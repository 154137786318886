import _ from 'lodash';
import {
  SLIDE_SHOW_TYPES,
  findCurrentSlide,
} from '../boxSlideShow/boxSlideShowUtils';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export const getFirstControllableComponent = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  source?: string,
): CompRef => {
  if (!compRef) {
    return;
  }

  let nextToBeSelected = compRef;
  while (
    editorAPI.components.is.delegateControlsToFirstChild(
      nextToBeSelected,
      source,
    )
  ) {
    const children =
      editorAPI.components.getChildrenOrScopedChildren(nextToBeSelected);

    if (children.length === 0) {
      return nextToBeSelected;
    }

    nextToBeSelected = children[0];
  }

  return nextToBeSelected;
};

export const shouldDelegate = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  firstChild: CompRef,
  source: unknown,
) =>
  editorAPI.components.is.delegateControlsToFirstChild(compRef, source) ||
  editorAPI.components.is.editDirectChildProperties(compRef, firstChild);

export const getFirstEditableCompRef = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  source?: unknown,
): CompRef => {
  let firstChild = _.head(
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(compRef),
  );
  let nextToBeSelected = compRef;

  while (
    firstChild &&
    shouldDelegate(editorAPI, nextToBeSelected, firstChild, source)
  ) {
    if (
      SLIDE_SHOW_TYPES.includes(editorAPI.components.getType(nextToBeSelected))
    ) {
      return findCurrentSlide(editorAPI.dsRead, compRef);
    }
    nextToBeSelected = firstChild;
    firstChild = _.head(
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
        nextToBeSelected,
      ),
    );
  }
  return nextToBeSelected;
};
