import validate from './validators/validate';
import validationExpressions from './validators/validationExpressions';
import * as link from './link/link';
import type { Anchor } from './link/link';
import UrlBuilder from './url/UrlBuilder';
import * as guidUtils from './utils/guid';
import * as jwtUtils from './utils/jwt';
import * as stringUtils from './utils/string';
import * as functionUtils from './utils/functionUtils';
import * as promiseUtils from './promise';
import type { CancelablePromise } from './promise';
import * as cookie from './utils/cookie';
import classNameUtils from './utils/classNames';
import cx from './utils/cx';
import { OuterClick as outerClick } from './outerClick/outerClick';
import {
  preventContextMenu,
  isRightClickWithCtrlInDebugMode,
} from './preventContextMenu/preventContextMenu';
import propTypesFilterMixin from './props/propTypesFilterMixin';
import { groupComponentsClusters } from './groupComponentClusters';
import isPropChanged from './props/isPropChanged';
import translationUtil from './translation/translationUtil';
import * as translationUtils from './translation/translationUtils';
import translationMixin from './translation/translationMixin';
import postMessageMixin from './postMessage/postMessageMixin';
import draggableMixin from './mixins/draggableMixin';
import * as headerScrollTypes from './headerScrollTypes/headerScrollTypes';
import * as imageEffects from './imageService/imageEffects';
import * as videoUtils from './video/videoUtils';
import selectionMixin from './mixins/selectionMixin';
import blockOuterScrollMixin from './mixins/blockOuterScrollMixin';
import * as addPanelHoverMixin from './mixins/addPanelHoverMixin';
import { timeoutsMixin } from './mixins/timeoutsMixin';
import componentUpdatesMixin from './mixins/componentUpdatesMixin';
import languages from './translation/languages';
import * as compIcon from './compIcon/compIcon';
import googleTranslate from './googleTranslate/googleTranslate';
import * as multilingual from './multilingual/multilingual';
import * as keyboardShortcuts from './keyboardShortcuts/keyboardShortcuts';
import * as object from './object/object';
import * as array from './array/array';
import * as colors from './colors';
import media from './media/media';
import keyGenerator from './media/keyGenerator';
import * as time from './time/time';
import * as bi from './bi/bi';
import { biLogger, biLoggerUpdateDefaults } from './bi/bi';
import * as uiControlsBIUtil from './bi/uiControlsBIUtil';
import * as dsOrigin from './bi/dsOrigin';
import serviceTopology from './serviceTopology/serviceTopology';
import svgUrlParser from './svg/svgUrlParser';
import * as imageOrganizerUtils from './imageOrganizerUtils/imageOrganizerUtils';
import * as editorSessionUtil from './editorSessionUtil/editorSessionUtil';
import * as polyfills from './polyfills/polyfills';
import * as browserUtil from './browser/browserUtil';
import fontLangsFallbacks from './fontLangsFallbacks/fontLangsFallbacks';
import * as backgroundUtilsEditor from './backgroundUtils/backgroundUtils';
import { pinModeUtils } from './layout/pinModeUtils';
import * as imageCrop from './imageCrop/imageCrop';
import windowInteractionUtils from './windowInteraction/windowInteractionUtils';
import * as uiUtils from './utils/uiUtils';
import performance from './browser/performance';
import qualaroo from './utils/integrations/qualaroo';
import { editorWixRecorder } from './utils/integrations/wixRecorder';
import fedopsLogger from './utils/integrations/fedopsLogger';
import dealerUtils from './utils/integrations/dealerUtils';
import * as splitterAPI from './ui/splitterAPI';
import * as fontsManagerUtils from './fontsManager/fontsManagerUtils';
import * as panelUtils from './panel/panelUtils';
import * as designData from './designData/designDataUtils';
import * as textUtils from './text/textUtils';
import * as fakeBrowserUtils from './fakeBrowser/fakeBrowserUtils';
import deepFreeze from './utils/deepFreeze';
import animationFrameRenderer from './utils/animationFrameRenderer';
import * as recommendedMobileHeaderHeightUtils from './utils/recommendedMobileHeaderHeightUtils';
import * as appStudioUtils from './appStudio/appStudioUtils';
import * as boxSlideShowUtils from './boxSlideShow/boxSlideShowUtils';
import * as spotlightStageUtils from './spotlightStage/spotlightStageUtils';
import * as inlinePopupUtils from './inlinePopup/inlinePopupUtils';
import * as mouseCoordinatesHandler from './mouseCoordinatesHandler/mouseCoordinatesHandler';
import * as animation from './animation/animation';
import * as scroll from './scroll/scroll';
import automationObserver from './automationObserver/automationObserver';
import * as valueLink from './valueLink/valueLink';
import reportUIChange from './hoc/reportUIChange';
import type { WithReportUIChange } from './hoc/reportUIChange';
import renderWhenMutated from './hoc/renderWhenMutated';
import withDragToStage from './hoc/withDragToStage';
import withDoubleClickPrevention from './hoc/withDoubleClickPrevention';
import type { WithDoubleClickPrevention } from './hoc/withDoubleClickPrevention';

import * as errorBoundary from './errorBoundary';
import consentPolicyManager from './consentPolicyManager';
import * as symbols from './symbols/index';
import * as tpaUtils from './tpa/index';
import {
  isAdvancedMenuOpen,
  isAdvancedMenusMigrationOpen,
  isCustomMenusEnabled,
  isCustomOrAdvancedMenuEnabled,
  isMainMenuFlowEnabled,
  isNewPagesPanelEnabled,
} from './menu';
import * as topBar from './topBar/index';
import * as workspace from './workspace/index';
import * as publishUtil from './publish/publishUtil';
import * as tours from './tours';
import * as hooks from './hooks/index';
import documentStyles from './documentStyles/documentStylesUtils';
import * as utils from '@wix/santa-editor-utils';
import * as componentsDistanceUtils from './snapTo/componentsDistanceUtils';
import * as candidatesUtil from './snapTo/candidatesUtil';
import * as snapDataUtils from './snapTo/snapDataUtils';
import * as snapToUtils from './snapTo/snapToUtils';
import * as chatManager from './utils/chatManager';
import * as pathUtils from './utils/path';
import * as overrideUtils from './utils/overrideUtils';
import { isDebugMode } from './utils/debugMode';
import * as localModeUtils from './localMode/localModeUtils';
import * as dataRouterPagesMenuItemPrefixUtils from './wixData/dataRouterPagesMenuItemPrefixUtils';
import * as collectionNamespaceUtils from './wixData/collectionNamespaceUtils';
import * as addPageRouterActionUtils from './wixData/addPageRouterActionUtils';
import * as a11yUtils from './a11y/a11yUtils';
import * as controlsUtils from './controls/controlsUtils';
import { wrapFunctionsWithPreventOnSilent, addElementsUtils } from './platform';
import ascend from './ascend/ascend';
import * as wixCodeUtils from './wixCode/wixCodeUtils';
import * as premiumUtils from './premium/premiumUtils';
import * as addPanel from './addPanel';
import * as animations from './animations/animationsUtils';
import * as sections from './sections';
import * as domain from './domain';
import * as componentLabel from './component/componentLabel';
import * as http from './http';
import * as fixedStage from './fixedStage';
import * as modes from './modes';
import * as zoomMode from './zoomMode';

import type { FrameProps } from './panel/panelUtils';
import type { StateMapperArgs } from 'types/redux';
import type {
  FittingType,
  AlignType,
  ImageTransformData,
  ImageTransformTarget,
  ImageTransformSource,
  ImageTransformOptions,
} from '@wix/santa-editor-utils';

type ConditionalRenderer = (
  connectType: any,
  fn: (args: StateMapperArgs) => boolean,
) => <C>(comp: C) => C & { pure: any };

const hoc = {
  reportUIChange,
  renderWhenMutated,
  withDragToStage,
  withConditionalRender: utils.hoc.withConditionalRender as ConditionalRenderer,
  withDoubleClickPrevention,
  errorBoundaryComponent: utils.hoc.errorBoundaryComponent,
  connect: utils.hoc.connect,
  STORES: utils.hoc.STORES,
};
const {
  url,
  math,
  editorModel,
  elementUtils,
  siteCreation,
  videoPlayerUtils,
  animationFrameUtils,
  imageTransform,
} = utils;
const { inheritClassName } = classNameUtils;
const path = pathUtils;
const platform = {
  addElementsUtils,
  wrapFunctionsWithPreventOnSilent,
};

export type {
  WithDoubleClickPrevention,
  WithReportUIChange,
  CancelablePromise,
  FrameProps,
  Anchor,
  FittingType,
  AlignType,
  ImageTransformData,
  ImageTransformTarget,
  ImageTransformSource,
  ImageTransformOptions,
};
export type { WithStartItemDrag } from './hoc/withDragToStage';
export type { WixRecorder } from './utils/integrations/wixRecorder';
export type { ViewerMouseCoordinates } from './mouseCoordinatesHandler/mouseCoordinatesHandler';
export type {
  BackgroundMediaProps,
  BackgroundMediaDispatchProps,
  BackgroundMediaPanelOwnProps,
  ColorOrGradient,
  MediaInfo,
  ColorValue,
  ColorLayerData,
} from './backgroundUtils/types';
export type {
  ComponentsCluster,
  ComponentsClusterLayout,
} from './groupComponentClusters/types';
export type {
  AlignmentLine,
  SnapCandidate,
  RectangleDrawData,
  SnapData,
  SnapTextLabel,
} from './snapTo/snapTo.types';

const backgroundUtils = {
  ...backgroundUtilsEditor,
  ...utils.backgroundUtils,
};

const { SafeInjectHtml, ImSureInputIsSafeInjectHtml } = utils;

export {
  addPanel,
  animations,
  validate,
  validationExpressions,
  link,
  url,
  chatManager,
  errorBoundary,
  UrlBuilder,
  guidUtils,
  jwtUtils,
  stringUtils,
  functionUtils,
  promiseUtils,
  cookie,
  domain,
  animationFrameUtils,
  inheritClassName,
  cx,
  outerClick,
  isRightClickWithCtrlInDebugMode,
  preventContextMenu,
  propTypesFilterMixin,
  isPropChanged,
  translationUtil,
  translationUtils,
  translationMixin,
  languages,
  postMessageMixin,
  draggableMixin,
  headerScrollTypes,
  imageTransform,
  imageEffects,
  videoUtils,
  videoPlayerUtils,
  timeoutsMixin,
  selectionMixin,
  blockOuterScrollMixin,
  addPanelHoverMixin,
  componentUpdatesMixin,
  compIcon,
  compIcon as compIconUtils,
  keyboardShortcuts,
  math,
  math as mathUtils,
  object,
  array,
  array as arrayUtils,
  colors,
  media,
  time,
  keyGenerator,
  bi,
  biLogger,
  biLoggerUpdateDefaults,
  groupComponentsClusters,
  uiControlsBIUtil,
  dsOrigin,
  serviceTopology,
  svgUrlParser,
  editorModel,
  polyfills,
  imageOrganizerUtils,
  editorSessionUtil,
  browserUtil,
  fontLangsFallbacks,
  backgroundUtils,
  pinModeUtils,
  imageCrop,
  windowInteractionUtils,
  uiUtils,
  googleTranslate,
  multilingual,
  performance,
  qualaroo,
  editorWixRecorder,
  fedopsLogger,
  dealerUtils,
  splitterAPI,
  designData,
  panelUtils,
  fontsManagerUtils,
  textUtils,
  fakeBrowserUtils,
  deepFreeze,
  elementUtils,
  animationFrameRenderer,
  recommendedMobileHeaderHeightUtils,
  appStudioUtils,
  wixCodeUtils,
  boxSlideShowUtils,
  spotlightStageUtils,
  inlinePopupUtils,
  mouseCoordinatesHandler,
  scroll,
  animation,
  automationObserver,
  valueLink,
  componentsDistanceUtils,
  candidatesUtil,
  snapDataUtils,
  snapToUtils,
  hoc,
  http,
  path,
  overrideUtils,
  isDebugMode,
  localModeUtils,
  dataRouterPagesMenuItemPrefixUtils,
  collectionNamespaceUtils,
  addPageRouterActionUtils,
  a11yUtils,
  controlsUtils,
  platform,
  ascend,
  consentPolicyManager,
  symbols,
  isAdvancedMenuOpen,
  isAdvancedMenusMigrationOpen,
  isCustomMenusEnabled,
  isCustomOrAdvancedMenuEnabled,
  isMainMenuFlowEnabled,
  isNewPagesPanelEnabled,
  topBar,
  workspace,
  publishUtil,
  documentStyles,
  tpaUtils,
  premiumUtils,
  tours,
  sections,
  sections as sectionsUtils,
  siteCreation,
  SafeInjectHtml,
  ImSureInputIsSafeInjectHtml,
  hooks,
  componentLabel,
  modes,
  fixedStage,
  zoomMode,
};

export * as smartForm from './useForm';
export * as mobileUtils from './mobileUtils';
export { fontUtils } from './fontUtils';

export * from './appStudio/appStudioUtils';
