import { FixedStageApiKey } from '@/apis';
import experiment from 'experiment';
import type { PreviewType } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';

let isEnabled: boolean | undefined;

const _setFixedStageEnabled = (value: boolean) => {
  isEnabled = value;
};

const isFixedStageEnabled = (): boolean => {
  return (
    experiment.isOpen('se_fixedStage') || // for dev purposes
    Boolean(isEnabled)
  );
};

const getTopBarStateBiParamValue = (editorAPI: EditorAPI) => {
  const fixedStageApi = editorAPI.host.getAPI(FixedStageApiKey);

  if (fixedStageApi.isTopBarMinimized()) {
    return 'only_icons_search_tools_multilingual';
  }

  const editorWidthIsLessThanMinWidth =
    editorAPI.store.getState().domMeasurements.editorContentLayout.width < // not a selector because we can't import state management in @/util package
    editorAPI.editorConfig.minTopBarWidth;

  return editorWidthIsLessThanMinWidth ? 'fb_shrink' : 'standard';
};

const shouldRenderFixedTopGap = (
  previewMode: boolean,
  previewType: PreviewType,
) => {
  const isEditorWithFixedStage = isFixedStageEnabled() && !previewMode;
  const isDesktopPreview =
    previewMode && isFixedStageEnabled() && previewType === 'desktop';

  return isEditorWithFixedStage || isDesktopPreview;
};

export {
  isFixedStageEnabled,
  getTopBarStateBiParamValue,
  _setFixedStageEnabled,
  shouldRenderFixedTopGap,
};
