export default {
  top_bar_click: {
    evid: 285,
    fields: {
      category: 'string',
      origin: 'string',
      is_published: 'boolean',
    },
  },
  top_bar_publish_popover_click: {
    evid: 1131,
    fields: {
      target: 'string',
    },
  },
  top_bar_tooltip_open: {
    evid: 1051,
    fields: {
      item: 'string',
      is_published: 'boolean',
    },
  },
  publish_view_your_site_click: {
    evid: 449,
    fields: {
      panel_name: 'string',
    },
  },
  top_bar_Preview_Page_Back_to_Editor_click: {
    evid: 293,
    fields: {
      origin: 'string',
    },
  },
  top_bar_Preview_Page_Save_click: {
    evid: 294,
  },
  top_bar_SITE_menu_click: {
    evid: 286,
    fields: {
      category: 'string',
    },
  },
  top_bar_SETTINGS_menu_click: {
    evid: 766,
  },
  top_bar_EDIT_menu_click: {
    evid: 288,
    fields: {
      category: 'string',
      component_id: 'string',
      component_type: 'string',
    },
  },
  top_bar_VIEW_menu_click: {
    evid: 287,
    fields: {
      category: 'string',
      status: 'boolean',
      origin: 'string',
      editor_view_mode: 'string',
    },
  },
  top_bar_CODE_menu_click: {
    evid: 790,
    fields: {
      category: 'string',
      status: 'boolean',
      editor_view_mode: 'string',
      origin: 'string',
      is_data_on: 'boolean',
      is_first: 'boolean',
    },
  },
  start_creating_rc_click: {
    evid: 892,
    fields: {
      origin: 'string',
      category: 'string',
    },
  },
  top_bar_WIXCODE_resources_clicked: {
    evid: 785,
    fields: {
      item_name: 'string',
      esi: 'string',
      site_id: 'guid',
    },
  },
  top_bar_HELP_menu_click: {
    evid: 460,
    fields: {
      category: 'string',
    },
  },
  top_bar_open_settings_click: {
    evid: 289,
  },
  top_bar_menu_item_hover: {
    evid: 987,
    fields: {
      menu_name: 'string',
      menu_item_name: 'string',
    },
  },
  top_bar_compare_plans_click: {
    evid: 290,
  },
  top_bar_get_yearly_unlimited_click: {
    evid: 455,
  },
  top_bar_editor_view_mode_click: {
    evid: 291,
    fields: {
      editor_view_mode: 'string',
    },
  },
  top_bar_zoom_mode_click: {
    evid: 548,
    fields: {
      editor_view_mode: 'string',
    },
  },
  TOP_BAR_PANEL_OPENED: {
    evid: 745,
    fields: {
      menu_name: 'string',
      is_published: 'boolean',
      origin: 'string',
    },
  },
  TOP_BAR_Dev_Mode_Panel_Opened: {
    evid: 1010,
    fields: {
      menu_name: 'string',
    },
  },
  upgrade_topbar_open: {
    evid: 789,
    fields: {
      type: 'string',
    },
  },
  click_save_upgrade_tooltip_topbar: {
    evid: 1389,
  },
  mobile_view_mode_toggle: {
    evid: 292,
    fields: {
      origin: 'string',
      status: 'bool',
    },
  },
  top_bar_preview_page_editor_view_mode_click: {
    evid: 295,
    fields: {
      editor_view_mode: 'string',
    },
  },
  editor_view_mode_switch: {
    evid: 568,
    fields: {
      mode: 'string',
      origin: 'string',
    },
  },
  arena_tooltip_opened: {
    evid: 80,
    fields: {
      clickType: 'string',
    },
  },
  arena_button_clicked: {
    evid: 82,
    fields: {
      origin: 'string',
    },
  },
  pages: {
    top_bar_PAGES_Add_pages_click: {
      evid: 413,
      fields: {
        origin: 'string',
      },
    },
    top_bar_PAGES_page_transition_click: {
      evid: 414,
      fields: {
        origin: 'string',
      },
    },
    top_bar_PAGES_menu_drag: {
      evid: 416,
      fields: {},
    },
    pages_menu_sub_page_created: {
      evid: 417,
      fields: {
        origin: 'string',
        type: 'string',
      },
    },
    top_bar_PAGES_menu_user_reverted_to_main_page: {
      evid: 418,
      fields: {
        origin: 'string',
      },
    },
    top_bar_PAGES_quick_actions_menu_click: {
      evid: 419,
      fields: {},
    },
    top_bar_PAGES_settings_close: {
      evid: 420,
      fields: {
        origin: 'string',
      },
    },
    top_bar_PAGES_settings_tab_click: {
      evid: 422,
      fields: {
        category: 'string',
      },
    },
    top_bar_PAGES_settings_page_info_home_page_change_click: {
      evid: 425,
      fields: {},
    },
    top_bar_PAGES_settings_page_info_home_page_change_set_click: {
      evid: 426,
      fields: {},
    },
    top_bar_PAGES_settings_duplicate_page_click: {
      evid: 427,
      fields: {},
    },
    top_bar_PAGES_settings_delete_page_click: {
      evid: 428,
      fields: {},
    },
    top_bar_PAGES_settings_privacy_language_selected_click: {
      evid: 430,
      fields: {
        language_selected: 'string',
        category: 'string',
      },
    },
    top_bar_PAGES_settings_privacy_changed: {
      evid: 431,
      fields: {
        privacy: 'string',
        page_id: 'string',
      },
    },
    top_bar_PAGES_settings_facebook_login_changed: {
      evid: 298,
      fields: {
        googlePlus: 'bool',
      },
    },
    top_bar_PAGES_settings_google_login_changed: {
      evid: 639,
      fields: {
        facebook: 'bool',
      },
    },
    top_bar_PAGES_settings_seo_google_preview_click: {
      evid: 432,
      fields: {},
    },
    top_bar_PAGES_settings_seo_google_preview_tooltip_hover: {
      evid: 771,
      fields: {},
    },
    top_bar_PAGES_settings_seo_title_set: {
      evid: 433,
      fields: {},
    },
    top_bar_PAGES_settings_seo_description_set: {
      evid: 434,
      fields: {},
    },
    top_bar_PAGES_settings_seo_keywords_set: {
      evid: 435,
      fields: {},
    },
    top_bar_PAGES_settings_seo_wiz_lets_go_click: {
      evid: 661,
      fields: {},
    },
    top_bar_PAGES_settings_hide_page_clicked: {
      evid: 662,
      fields: {
        title: 'string',
        toggle: 'bool',
      },
    },
    top_bar_PAGES_settings_seo_go_to_url_click: {
      evid: 663,
      fields: {},
    },
    top_bar_PAGES_settings_hide_from_mobile_done_click: {
      evid: 436,
      fields: {
        category: 'string',
      },
    },
    top_bar_PAGES_settings_signup_settings: {
      evid: 615,
      src: 5,
      endpoint: 'site-members',
      fields: {
        name: 'string',
        value: 'string',
      },
    },
    top_bar_PAGES_settings_signup_link_to_policy: {
      evid: 617,
      src: 5,
      endpoint: 'site-members',
      fields: {
        name: 'string',
        action: 'string',
        type: 'string',
      },
    },
    top_bar_PAGES_special_pages_icon_click: {
      evid: 452,
      fields: {
        type: 'string',
      },
    },
    top_bar_PAGES_special_pages_back_click: {
      evid: 453,
      fields: {},
    },
    top_bar_PAGES_store_pages_store_manager_click: {
      evid: 439,
      fields: {},
    },
    pages_panel_page_settings_click: {
      evid: 607,
      src: 38,
      fields: {
        page_on_top: 'bool',
        page_ind: 'string',
      },
    },
    pages_panel_page_settings_SEO_tab_click_delete_image: {
      evid: 815,
      src: 38,
      fields: {},
    },
    pages_panel_page_settings_SEO_tab_click_upload_image: {
      evid: 824,
      src: 38,
      fields: {},
    },
    pages_panel_page_settings_SEO_tab_upload_image_success: {
      evid: 826,
      src: 38,
      fields: {},
    },
    pages_panel_page_settings_SEO_tab_hover_preview_image: {
      evid: 827,
      src: 38,
      fields: {},
    },
    top_bar_PAGES_navigate_to_pop_up: {
      evid: 559,
      fields: {
        pop_up_id: 'string',
      },
    },
    pages_menu_tab_click: {
      evid: 563,
      fields: {
        value: 'string',
      },
    },
    page_or_pop_up_deleted: {
      evid: 564,
      fields: {
        page_or_popup: 'string',
        page_popup_id: 'string',
        page_popup_name: 'string',
      },
    },
    custom_signup_enable: {
      endpoint: 'site-members',
      src: 5,
      evid: 639,
    },
    custom_signup_panel_enable_toggle: {
      endpoint: 'site-members',
      src: 5,
      evid: 640,
      fields: {
        toggle_state: 'bool',
      },
    },
    custom_signup_panel_save: {
      endpoint: 'site-members',
      src: 5,
      evid: 641,
    },
    custom_signup_panel_link_panel_open: {
      endpoint: 'site-members',
      src: 5,
      evid: 642,
    },
    custom_signup_panel_link_panel_save: {
      endpoint: 'site-members',
      src: 5,
      evid: 644,
    },
    custom_signup_panel_link_panel_cancel: {
      endpoint: 'site-members',
      src: 5,
      evid: 645,
    },
    custom_signup_type_selection: {
      endpoint: 'site-members',
      src: 5,
      evid: 859,
      fields: {
        hosting: 'string',
        selection: 'string',
      },
    },
    custom_signup_connect_link: {
      endpoint: 'site-members',
      evid: 861,
      src: 5,
      fields: {
        hosting: 'string',
        action: 'string',
      },
    },
  },
  top_bar_branch_indicator: {
    brach_name_hover: {
      evid: 527,
      src: 105,
      fields: {
        site_branch_id: 'string',
        site_branch_name: 'string',
      },
    },
    manage_site_branches_clicked: {
      evid: 528,
      src: 105,
      fields: {
        site_branch_id: 'string',
        site_branch_name: 'string',
        origin: 'string',
      },
    },
  },
  urlBar: {
    connect_domain_click: {
      evid: 1146,
      fields: {
        domain_type: 'string',
      },
    },
    connect_domain_panel_action_click: {
      evid: 1147,
      fields: {
        action: 'string',
        domain_type: 'string',
        origin: 'string',
      },
    },
    suggested_domain_show: {
      evid: 1148,
    },
  },
} as const;
