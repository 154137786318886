export const PANEL_WIDTH = 288;
export const PANEL_EDGE_OFFSET = 20;
export const PANEL_OFFSET_Y = 96;
export const SECTION_MIN_HEIGHT = 40;
export const HEADER_MIN_HEIGHT = 54;
export const FOOTER_MIN_HEIGHT = 40;
export const STAGE_SIDE_AREA_WIDTH_IN_ZOOM_MODE_AND_MOBILE = 234;

export const enum SectionTypes {
  Section = 'section',
  Header = 'header',
  Footer = 'footer',
}
export const PAGE_SECTIONS_EDITOR_DATA_NAMESPACE = 'pageSections';
