import type { EditorAPI } from '@/editorAPI';

export const getMultilingualBiParam = (editorAPI: EditorAPI): string | null => {
  if (!editorAPI.language.multilingual.isEnabled()) {
    return null;
  }

  const originalLanguage = editorAPI.language.original.get();
  const currentLanguage = editorAPI.language.current.get();

  if (originalLanguage.languageCode === currentLanguage) {
    return null;
  }

  return `${originalLanguage.languageCode},${currentLanguage}`;
};
