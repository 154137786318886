// @ts-nocheck
import * as util from '@/util';
import * as utils from '@wix/santa-editor-utils';
import experiment from 'experiment';

const TRANSLATE_ENDPOINT = 'https://www.googleapis.com/language/translate/v2';
const BROWSER_KEY = 'AIzaSyDk459Frxeg68etyrYPBbaopQePV40ChZk';
const NO_TRANSLATION = 'blank';

function success(callback, response) {
  const translation =
    response?.data?.translations?.[0]?.translatedText ?? NO_TRANSLATION;
  callback(translation);
}

function error(callback) {
  callback(NO_TRANSLATION);
}

export default function (string, callback) {
  if (
    experiment.isOpen('urlFormatGoogleTranslate') &&
    !utils.url.isTrue('isqa')
  ) {
    const fetchUrl = `${TRANSLATE_ENDPOINT}?key=${BROWSER_KEY}&target=en&q=${encodeURIComponent(
      string,
    )}`;
    util.http
      .fetchJson(fetchUrl)
      .then((jsonResult) => success(callback, jsonResult))
      .catch(() => error(callback));
  } else {
    callback(string);
  }
}
