import * as googleTranslateApi from './googleTranslateApi';
import performance from '../browser/performance';
import fedopsLogger from '../utils/integrations/fedopsLogger';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { Translatable, TranslatedItem } from './googleTranslateApi';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, LanguageDefinition } from 'types/documentServices';
import counter from '@wix/multilingual-words-counter';
import { DataBindingApiKey } from '@/apis';

const googleTranslateMarkName = 'googleTranslate';
const pageComponentType = 'page';

const googleTranslateComponentsMapper: AnyFixMe = {
  StyledText: 'text',
  LinkableButton: 'label',
  StylableButton: 'label',
  TextFxData: 'text',
};

/**
 * @param  {object} editorAPI
 * @param  {object} menuItem
 * @param  {function} callback - firing after the text has been translated with the translated text
 * @returns {function} call to this function will translate the menuItem
 */
const getTranslateMethodForMenuItem = (
  editorAPI: AnyFixMe,
  stateManagement: AnyFixMe,
  menuItem: AnyFixMe,
  callback: (translatedItem: string) => void,
) => {
  try {
    if (!editorAPI.language.isCurrentLanguageSecondary()) {
      return null;
    }

    const originalTitle = editorAPI.menu.getById(menuItem.id, true).label;

    const component = {
      // menuItem.pageData null for non pages link (folders, external links)
      id: menuItem.pageData ? menuItem.pageData.id : menuItem.id,
      type: pageComponentType,
    };
    return getTranslateMethod(
      editorAPI,
      stateManagement,
      { text: originalTitle, text_format: 'TEXT' },
      component,
      callback,
    );
  } catch (error) {
    ErrorReporter.captureException(
      `can't get translationMethod. Reason: ${error}`,
      {},
    );
  }
};
/**
 * @param  {object} editorAPI
 * @param  {object} compRef
 * @returns {function} call to this function will translate the component content and trigger UI effects
 */
const getTranslateMethodForComponent = (
  editorAPI: EditorAPI,
  stateManagement: AnyFixMe,
  compRef: CompRef,
) => {
  try {
    const componentData = editorAPI.components.data.get(compRef, true);
    if (!componentData) {
      return null;
    }

    // If it's a bound component return null because shouldn't have auto translate
    const dataBindingApi = editorAPI.host.getAPI(DataBindingApiKey);
    const hasDataBindingConnection =
      dataBindingApi.getDataBindingConnection(compRef);
    if (hasDataBindingConnection) {
      return null;
    }

    const textProperty = googleTranslateComponentsMapper[componentData.type];
    const originalComponentText = componentData[textProperty];
    const updateComponentWithTranslation = (translated: string) => {
      const newComponentData = Object.assign({}, componentData, {
        [textProperty]: translated,
      });
      const wasEditing = editorAPI.text.isEditingText();
      if (wasEditing) {
        editorAPI.text.stopEditing();
      }
      editorAPI.components.data.update(compRef, newComponentData);

      if (wasEditing) {
        editorAPI.dsActions.waitForChangesApplied(() => {
          editorAPI.text.startEditing();
        });
      }
    };

    if (!textProperty || !originalComponentText) {
      return null;
    }

    return getTranslateMethod(
      editorAPI,
      stateManagement,
      { text: originalComponentText, text_format: 'HTML' },
      compRef,
      updateComponentWithTranslation,
    );
  } catch (error) {
    ErrorReporter.captureException(
      `can't get translationMethod. Reason: ${error}`,
      {},
    );
  }
};

const showNotification = (editorAPI: EditorAPI, stateManagement: AnyFixMe) =>
  editorAPI.store.dispatch(
    stateManagement.multilingual.actions.showNumCreditsLeft(),
  );

const openGoogleTranslateFailed = (
  editorAPI: EditorAPI,
  stateManagement: AnyFixMe,
  compForBi: AnyFixMe,
  intent = 'auto',
) => {
  editorAPI.store.dispatch(
    stateManagement.panels.actions.updateOrOpenPanel(
      'panels.focusPanels.googleTranslateFailed',
      { compForBi, intent },
      false,
    ),
  );
};

const toggleComponentPreloaderIfNeed = (
  compRef: AnyFixMe,
  editorAPI: EditorAPI,
  stateManagement: AnyFixMe,
  show: boolean,
) => {
  if (compRef.type === pageComponentType) {
    return;
  }
  const action = show
    ? stateManagement.preloadings.actions.setComponentPreloading
    : stateManagement.preloadings.actions.clearPreloadingComponent;
  editorAPI.store.dispatch(action(compRef));
};

const getCompForBi = (compRef: AnyFixMe, editorAPI: EditorAPI) => {
  if (compRef.type === pageComponentType) {
    return compRef;
  }
  const { id, type } = editorAPI.components.data.get(compRef);
  return {
    id,
    type,
  };
};

/**
 * @param  {EditorAPI} editorAPI
 * @param  {object} compRef
 * @param  {number} stringLength
 * @returns {function} - post translate callback
 */
const preTranslate = (
  editorAPI: EditorAPI,
  stateManagement: AnyFixMe,
  compRef: CompRef,
  stringLength: number,
  numberOfWords: number,
  originalLanguageCode: string,
  translationLanguageCode: string,
) => {
  const compForBi = getCompForBi(compRef, editorAPI);
  toggleComponentPreloaderIfNeed(compRef, editorAPI, stateManagement, true);
  performance.mark(googleTranslateMarkName);
  stateManagement.multilingual.services.multilingualBi.googleTranslateSent(
    editorAPI,
    stringLength,
    compForBi,
    numberOfWords,
    originalLanguageCode,
    translationLanguageCode,
  );
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.GOOGLE_TRANSLATE.TRANSLATE,
  );

  return (translationResult: TranslatedItem) => {
    const success = translationResult && translationResult.status === 'SUCCESS';
    postTranslateBi(
      editorAPI,
      stateManagement,
      stringLength,
      success ? translationResult.translation.length : undefined,
      numberOfWords,
      success,
      compForBi,
    );

    if (translationResult === undefined) {
      openGoogleTranslateFailed(editorAPI, stateManagement, compForBi);
    } else if (translationResult.status === 'ITEM_TOO_LONG') {
      openGoogleTranslateFailed(
        editorAPI,
        stateManagement,
        compForBi,
        'toolong',
      );
    } else {
      showNotification(editorAPI, stateManagement);
    }
  };
};

/**
 * @param  {EditorAPI} editorAPI
 * @param  {object} stateManagement
 * @param  {number} stringLength
 * @param  {number} [stringLengthReceived]
 * @param  {boolean} success
 * @param  {object} compRef
 */
const postTranslateBi = (
  editorAPI: EditorAPI,
  stateManagement: AnyFixMe,
  stringLength: number,
  stringLengthReceived: number,
  numberOfWords: number,
  success: boolean,
  compRef: CompRef,
) => {
  performance.measure(googleTranslateMarkName);
  const { duration } = performance.getMeasure(googleTranslateMarkName);
  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.GOOGLE_TRANSLATE.TRANSLATE,
  );

  const state = editorAPI.store.getState();
  const { machineTranslateWordsLimit, machineTranslationWordsUsed } =
    stateManagement.multilingual.selectors.machineTranslationLimit(state);
  const words_in_credit =
    machineTranslateWordsLimit - machineTranslationWordsUsed + numberOfWords;
  const words_remain = machineTranslateWordsLimit - machineTranslationWordsUsed;

  stateManagement.multilingual.services.multilingualBi.googleTranslateReceived(
    editorAPI,
    stringLength,
    stringLengthReceived,
    numberOfWords,
    success,
    duration,
    compRef,
    words_in_credit,
    words_remain,
  );
};

const captureError = (err: AnyFixMe) => {
  if (!err) {
    return;
  }
  const { statusText, status } = err;
  ErrorReporter.captureException(
    `google translate failed. Reason: ${status}, ${statusText}`,
    {},
  );
};

/**
 * @param  {EditorAPI} editorAPI
 * @param  {Translatable} itemToTranslate - text to translate
 * @param  {object} compRef
 * @param  {(translatedItem: string) => void} callback - firing after the text has been translated with the translated text
 * @returns {() => void} call it in the appropriate time
 */
const getTranslateMethod = (
  editorAPI: EditorAPI,
  stateManagement: AnyFixMe,
  itemToTranslate: Translatable,
  compRef: AnyFixMe,
  callback: (translatedItem: string) => void,
): (() => void) => {
  const originalLanguage = editorAPI.language.original.get();
  const currentLanguageCode = editorAPI.language.current.get();

  const originalLanguageMachineTranslationCode =
    originalLanguage.machineTranslationLanguageCode;

  const translationLanguage = editorAPI.language
    .getFull()
    .find((lang: LanguageDefinition) => lang.code === currentLanguageCode);
  const currentLanguageMachineTranslationCode =
    translationLanguage.machineTranslationLanguageCode;

  if (
    originalLanguageMachineTranslationCode &&
    currentLanguageMachineTranslationCode
  ) {
    return async () => {
      const numberOfWords = counter.countWords(
        itemToTranslate.text,
        'rich_text',
      );
      let wordsRemain = numberOfWords;
      await editorAPI.store.dispatch(
        stateManagement.multilingual.actions.getMachineTranslationLimitThunk(),
      );
      const state = editorAPI.store.getState();
      const machineTranslationLimit =
        stateManagement.multilingual.selectors.machineTranslationLimit(state);
      if (machineTranslationLimit === 'failed') {
        editorAPI.store.dispatch(
          stateManagement.multilingual.actions.showGoogleTranslateFailedNotification(
            () =>
              getTranslateMethod(
                editorAPI,
                stateManagement,
                itemToTranslate,
                compRef,
                callback,
              ),
          ),
        );
        return;
      }
      wordsRemain =
        machineTranslationLimit.machineTranslateWordsLimit -
        machineTranslationLimit.machineTranslationWordsUsed;

      if (wordsRemain >= numberOfWords) {
        await translate(
          stateManagement,
          editorAPI,
          compRef,
          itemToTranslate,
          originalLanguage,
          translationLanguage,
          callback,
        );
      } else {
        editorAPI.store.dispatch(
          stateManagement.panels.actions.updateOrOpenPanel(
            'panels.focusPanels.notEnoughWordsToGoogleTranslate',
            {
              wordsNeeded: numberOfWords - wordsRemain,
              wordsInCredit: wordsRemain,
              wordsTranslated: numberOfWords,
              mainLanguage: originalLanguage.code,
              secondaryLanguage: translationLanguage.code,
            },
          ),
        );
      }
    };
  }
  return null;
};

const translate = async (
  stateManagement: AnyFixMe,
  editorAPI: EditorAPI,
  compRef: CompRef,
  itemToTranslate: Translatable,
  originalLanguage: LanguageDefinition,
  translationLanguage: LanguageDefinition,
  callback: AnyFixMe,
) => {
  const postTranslate = preTranslate(
    editorAPI,
    stateManagement,
    compRef,
    itemToTranslate.text.length,
    counter.countWords(itemToTranslate.text, 'rich_text'),
    originalLanguage.code,
    translationLanguage.code,
  );

  let translationResult: TranslatedItem;
  const originalLanguageMachineTranslationCode =
    originalLanguage.machineTranslationLanguageCode;
  const currentLanguageMachineTranslationCode =
    translationLanguage.machineTranslationLanguageCode;
  const currentLanguageCountryCode = translationLanguage.countryCode;
  try {
    translationResult = (await googleTranslateApi.translate(
      editorAPI.dsRead,
      originalLanguageMachineTranslationCode,
      currentLanguageMachineTranslationCode,
      currentLanguageCountryCode,
      itemToTranslate,
    )) as unknown as TranslatedItem;
  } catch (err) {
    captureError(err);
  }

  await editorAPI.store.dispatch(
    stateManagement.multilingual.actions.getMachineTranslationLimitThunk(),
  );

  postTranslate(translationResult);

  toggleComponentPreloaderIfNeed(compRef, editorAPI, stateManagement, false);

  if (translationResult && translationResult.status === 'SUCCESS') {
    callback(translationResult.translation);
  }
};

export { getTranslateMethodForComponent, getTranslateMethodForMenuItem };
