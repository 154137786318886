import constants from '@/constants';
import experiment from 'experiment';
import { toWBU } from './svgForCompUtil';
import { getAppWidgetIconName, getRefComponentIconName } from './iconResolvers';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';
import type { IconNameMap } from './types';

const wbuIconsEnabled = experiment.isOpen('se_newIconsForLayers');

const wbuIconsList: { default: string } & IconNameMap = {
  default: toWBU('Border'),
  'wysiwyg.viewer.components.MatrixGallery': toWBU('ImageGallery'),
  'wysiwyg.viewer.components.SliderGallery': toWBU('ImageGallery'),
  'wysiwyg.viewer.components.PaginatedGridGallery': toWBU('ImageGallery'),
  'tpa.viewer.components.Honeycomb': toWBU('ImageGallery'),
  'tpa.viewer.components.Thumbnails': toWBU('ImageGallery'),
  'tpa.viewer.components.Collage': toWBU('ImageGallery'),
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel': toWBU('ImageGallery'),
  'wysiwyg.viewer.components.tpapps.TPA3DGallery': toWBU('ImageGallery'),
  'tpa.viewer.components.Freestyle': toWBU('ImageGallery'),
  'tpa.viewer.components.Masonry': toWBU('ImageGallery'),
  'tpa.viewer.components.TPAGallery': toWBU('ImageGallery'),
  'tpa.viewer.components.Impress': toWBU('ImageGallery'),
  'tpa.viewer.components.Accordion': toWBU('ImageGallery'),
  'tpa.viewer.components.StripShowcase': toWBU('ImageGallery'),
  'wysiwyg.viewer.components.VerticalLine': toWBU('VerticalLine'),
  'wysiwyg.viewer.components.FiveGridLine': toWBU('HorizontalLine'),
  'wysiwyg.viewer.components.menus.DropDownMenu': toWBU('HorizontalMenu'),
  'wysiwyg.viewer.components.HorizontalMenu': toWBU('HorizontalMenu'),
  'wixui.StylableHorizontalMenu': toWBU('HorizontalMenu'),
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu':
    toWBU('VerticalMenu'),
  'wysiwyg.common.components.anchor.viewer.Anchor': toWBU('Anchor'),
  'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu':
    toWBU('Anchor'),
  'wixui.SearchBox': toWBU('Search'),
  'wysiwyg.viewer.components.Grid': toWBU('Table'),
  'wixui.SignatureInput': toWBU('ESignature'),
  'wysiwyg.viewer.components.PopupContainer': toWBU('Lightbox'),
  'wysiwyg.viewer.components.PopupCloseIconButton': toWBU('Close'),
  'wysiwyg.viewer.components.Repeater': toWBU('Repeater'),
  'wysiwyg.viewer.components.WRichText': toWBU('Text'),
  'wixui.CollapsibleText': toWBU('Text'),
  'wixui.TextMask': toWBU('Text'),
  'wysiwyg.viewer.components.SiteButton': toWBU('Button'),
  'wixui.StylableButton': toWBU('Button'),
  'wysiwyg.common.components.imagebutton.viewer.ImageButton':
    toWBU('ImageButton'),
  'wysiwyg.viewer.components.inputs.FileUploader': toWBU('UploadButton'),
  'wysiwyg.viewer.components.ItunesButton': toWBU('Music'),
  'wysiwyg.viewer.components.documentmedia.DocumentMedia': toWBU('File'),
  'wysiwyg.viewer.components.tpapps.TPAWidget': toWBU('AppMarket'),
  'wysiwyg.viewer.components.SlideShowGallery': toWBU('SlideShow'),
  'wysiwyg.viewer.components.VectorImage': toWBU('Shape'),
  'wysiwyg.viewer.components.MediaOverlayControls': toWBU('Shape'),
  'wysiwyg.viewer.components.MediaPlayer': toWBU('VideoBox'),
  'wysiwyg.viewer.components.StripContainerSlideShow': toWBU('FullWidthSlider'),
  'wysiwyg.viewer.components.BoxSlideShow': toWBU('SlideShow'),
  'wysiwyg.viewer.components.BoxSlideShowSlide': toWBU('SlideShow'),
  'wysiwyg.viewer.components.StripContainerSlideShowSlide': toWBU('SlideShow'),
  'wysiwyg.viewer.components.StateBox': toWBU('MultiStateBox'),
  'wixui.Tabs': toWBU('Tabs'),
  'wixui.ProgressBar': toWBU('ProgressBar'),
  'wixui.Pagination': toWBU('Pagination'),
  'wysiwyg.viewer.components.StripColumnsContainer': toWBU('Strip'),
  'wysiwyg.viewer.components.Column': toWBU('Column'),
  'wixui.VideoPlayer': toWBU('Video'),
  'wixui.MusicPlayer': toWBU('Sound'),
  'wysiwyg.common.components.spotifyplayer.viewer.SpotifyPlayer':
    toWBU('Spotify'),
  'wysiwyg.common.components.spotifyfollow.viewer.SpotifyFollow':
    toWBU('Spotify'),
  'wysiwyg.viewer.components.SoundCloudWidget': toWBU('AppMarket'),
  'wysiwyg.viewer.components.WFacebookLike': toWBU('Facebook'),
  'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox':
    toWBU('Facebook'),
  'wysiwyg.viewer.components.WFacebookComment': toWBU('Facebook'),
  'wysiwyg.viewer.components.FacebookShare': toWBU('Facebook'),
  'wysiwyg.viewer.components.WTwitterFollow': toWBU('Twitter'),
  'wysiwyg.viewer.components.WTwitterTweet': toWBU('Twitter'),
  'wysiwyg.viewer.components.TwitterFeed': toWBU('Twitter'),
  'wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget':
    toWBU('Pinterest'),
  'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt':
    toWBU('Pinterest'),
  'wysiwyg.viewer.components.PinterestFollow': toWBU('Pinterest'),
  'wixui.LineShareButton': toWBU('LineShare'),
  'wysiwyg.viewer.components.VKShareButton': toWBU('Border'),
  'wysiwyg.viewer.components.LinkBar': toWBU('SocialBar'),
  'wysiwyg.viewer.components.PayPalButton': toWBU('Paypal'),
  'wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton':
    toWBU('Youtube'),
  'wysiwyg.viewer.components.GoogleMap': toWBU('MapPin'),
  'wysiwyg.viewer.components.tpapps.TPAGluedWidget': toWBU('AppMarket'),
  'wysiwyg.viewer.components.HtmlComponent': toWBU('Html'),
  'wysiwyg.viewer.components.inputs.TextInput': toWBU('Input'),
  'wysiwyg.viewer.components.inputs.TextAreaInput': toWBU('TextBox'),
  'wixui.RichTextBox': toWBU('RichTextBox'),
  'wysiwyg.viewer.components.inputs.RadioGroup': toWBU('RadioButton'),
  'wysiwyg.viewer.components.inputs.CheckboxGroup': toWBU('CheckboxList'),
  'wysiwyg.viewer.components.inputs.Checkbox': toWBU('Checkbox'),
  'wixui.SelectionTagsList': toWBU('Tag'),
  'wysiwyg.viewer.components.inputs.DatePicker': toWBU('Calendar'),
  'wixui.TimePicker': toWBU('TimeInput'),
  'wysiwyg.viewer.components.inputs.ComboBoxInput': toWBU('Dropdown'),
  'wixui.Slider': toWBU('Slider'),
  'wixui.RangeSlider': toWBU('Slider'),
  'wixui.RatingsInput': toWBU('Star'),
  'wixui.RatingsDisplay': toWBU('Star'),
  'wixui.AddressInput': toWBU('AddressInput'),
  'wixui.ToggleSwitch': toWBU('Switch'),
  'wixui.Captcha': toWBU('Captcha'),
  'mobile.core.components.Page': toWBU('PageBody'),
  'wysiwyg.viewer.components.HeaderContainer': toWBU('Header'),
  'wysiwyg.viewer.components.FooterContainer': toWBU('Footer'),
  'wysiwyg.viewer.components.ClassicSection': toWBU('Section'),
  'wixui.LottieEmbed': toWBU('Lottie'),
  'wysiwyg.viewer.components.LanguageSelector': toWBU('Globe'),
  'wysiwyg.viewer.components.ExpandableMenu': toWBU('VerticalMenu'),
  'wysiwyg.viewer.components.mobile.TinyMenu': toWBU('VerticalMenu'),
  'wysiwyg.viewer.components.MenuToggle': toWBU('Menu'),
  'wixui.CustomElementComponent': toWBU('Html'),
  'wysiwyg.viewer.components.MediaContainer': toWBU('Box'),
  'wysiwyg.viewer.components.MenuContainer': toWBU('Box'),
  'mobile.core.components.Container': toWBU('Box'),
  'platform.components.AppWidget': getAppWidgetIconName,
  'wysiwyg.viewer.components.RefComponent': getRefComponentIconName,
};

const localIconsList: { default: string } & IconNameMap = {
  default: 'default',
  'wysiwyg.viewer.components.WRichText': 'text',
  'wysiwyg.viewer.components.MediaRichText': 'text',
  'wysiwyg.viewer.components.WPhoto': 'wPhoto',
  'wysiwyg.viewer.components.MatrixGallery': 'galleries',
  'wysiwyg.viewer.components.SlideShowGallery': 'slideShow',
  'wysiwyg.viewer.components.SliderGallery': 'galleries',
  'wysiwyg.viewer.components.PaginatedGridGallery': 'galleries',
  'tpa.viewer.components.Honeycomb': 'galleries',
  'tpa.viewer.components.Thumbnails': 'galleries',
  'tpa.viewer.components.Collage': 'galleries',
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel': 'galleries',
  'wysiwyg.viewer.components.tpapps.TPA3DGallery': 'galleries',
  'tpa.viewer.components.Freestyle': 'galleries',
  'wysiwyg.viewer.components.Video': 'video',
  'wysiwyg.viewer.components.MediaPlayer': 'video',
  'wysiwyg.viewer.components.SoundCloudWidget': 'soundCloud',
  'wysiwyg.viewer.components.AudioPlayer': 'audioPlayer',
  'mobile.core.components.Container': 'container',
  'wysiwyg.viewer.components.VerticalLine': 'verticalLine',
  'wysiwyg.viewer.components.FiveGridLine': 'horizontalLine',
  'wysiwyg.viewer.components.ClipArt': 'WPhoto',
  'wysiwyg.viewer.components.SiteButton': 'button',
  'wysiwyg.common.components.imagebutton.viewer.ImageButton': 'imageButton',
  'wysiwyg.viewer.components.ItunesButton': 'itunesButton',
  'wysiwyg.viewer.components.menus.DropDownMenu': 'horizontalMenu',
  'wysiwyg.viewer.components.documentmedia.DocumentMedia': 'documentMedia',
  'wysiwyg.viewer.components.HorizontalMenu': 'horizontalMenu',
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': 'verticalMenu',
  'wysiwyg.viewer.components.mobile.TinyMenu': 'tinyMenu',
  'wysiwyg.viewer.components.WFacebookLike': 'facebook',
  'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox':
    'facebook',
  'wysiwyg.viewer.components.WFacebookComment': 'facebook',
  'wysiwyg.viewer.components.WTwitterFollow': 'twitter',
  'wysiwyg.viewer.components.WTwitterTweet': 'twitter',
  'wysiwyg.viewer.components.TwitterFeed': 'twitter',
  'wysiwyg.viewer.components.WGooglePlusOne': 'googlePlus',
  'wysiwyg.viewer.components.LinkBar': 'linkBar',
  'tpa.viewer.components.TPASection': 'tpaPageType',
  'tpa.viewer.components.TPAWidget': 'tpaPageType',
  'wysiwyg.viewer.components.tpapps.TPASection': 'tpaPageType',
  'wysiwyg.viewer.components.tpapps.TPAMultiSection': 'tpaPageType',
  'wysiwyg.viewer.components.tpapps.TPAWidget': 'tpaPageType',
  'wysiwyg.viewer.components.tpapps.TPAGluedWidget': 'tpaPageType',
  'tpa.viewer.components.TPAGallery': 'galleries',
  'tpa.viewer.components.TPAComponent': 'tpaPageType',
  'wysiwyg.viewer.components.ContactForm': 'contactForm',
  'wysiwyg.viewer.components.DynamicContactForm': 'contactForm',
  'wysiwyg.viewer.components.GoogleMap': 'googleMaps',
  'wysiwyg.viewer.components.HtmlComponent': 'htmlComponent',
  'wysiwyg.viewer.components.FlashComponent': 'flashComponent',
  'wysiwyg.viewer.components.PayPalButton': 'paypal',
  'wysiwyg.viewer.components.FlickrBadgeWidget': 'flicker',
  'wysiwyg.viewer.components.AdminLogin': 'webMasterLogin',
  'wysiwyg.viewer.components.AdminLoginButton': 'webMasterLogin',
  'wysiwyg.viewer.components.LoginButton': 'memberLogin',
  'wysiwyg.viewer.components.LoginSocialBar': 'memberLogin',
  'wixapps.integration.components.AppPart': 'blog',
  'wixapps.integration.components.AppPart2': 'listBuilder',
  'wysiwyg.viewer.components.svgshape.SvgShape': 'svgShape',
  'wysiwyg.viewer.components.VectorImage': 'svgShape',
  'wysiwyg.viewer.components.MediaOverlayControls': 'svgShape',
  'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': 'contactForm',
  'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer':
    'singleAudioPlayer',
  'wysiwyg.viewer.components.BgImageStrip': 'strip',
  'wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton':
    'youtubeSubscribe',
  'wysiwyg.viewer.components.HeaderContainer': 'headerContainer',
  'wysiwyg.viewer.components.FooterContainer': 'footerContainer',
  'wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget': 'pinterset',
  'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt': 'pinterset',
  'wysiwyg.viewer.components.PinterestFollow': 'pinterset',
  'wysiwyg.common.components.skypecallbutton.viewer.SkypeCallButton': 'skype',
  'wysiwyg.viewer.components.VKShareButton': 'vkShare',
  'wysiwyg.common.components.spotifyfollow.viewer.SpotifyFollow':
    'spotifyFollow',
  'wysiwyg.common.components.spotifyplayer.viewer.SpotifyPlayer':
    'spotifyFollow',
  'wysiwyg.common.components.anchor.viewer.Anchor': 'anchor',
  'wysiwyg.common.components.rssbutton.viewer.RSSButton': 'rssButton',
  'wysiwyg.viewer.components.inputs.DatePicker': 'datePicker',
  'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu':
    'anchor',
  'wysiwyg.viewer.components.Grid': 'grid',
  'wixui.Pagination': 'pagination',
  'wixui.VideoPlayer': 'video',
  'wixui.MusicPlayer': 'music-player-volume',
  'wixui.ToggleSwitch': 'toggleSwitch',
  'wixui.Slider': 'slider',
  'wixui.Captcha': 'captcha',
  'mobile.core.components.Page': 'rcmPage',
  'wixui.RatingsDisplay': 'ratingsDisplay',
  'wixui.RatingsInput': 'ratingsDisplay',
  'wysiwyg.viewer.components.LanguageSelector': 'languageMenu',
  'wysiwyg.viewer.components.inputs.TextInput': 'input',
  'wysiwyg.viewer.components.inputs.TextAreaInput': 'textBox',
  'wysiwyg.viewer.components.inputs.ComboBoxInput': 'dropDown',
  'wysiwyg.viewer.components.inputs.RadioGroup': 'radioButton',
  'platform.components.AppWidget': 'customWidgetLight',
  'wixui.TimePicker': 'timePicker',
  'wixui.RichTextBox': 'richTextBox',
  'wysiwyg.viewer.components.inputs.Checkbox': 'checkbox',
  'wysiwyg.viewer.components.Repeater': 'repeater',
  'wysiwyg.viewer.components.inputs.FileUploader': 'uploadButton',
  'wysiwyg.viewer.components.MediaContainer': 'container',
  'tpa.viewer.components.Masonry': 'galleries',
  'wysiwyg.viewer.components.StripContainerSlideShow': 'fullWidthSlideshow',
  'wysiwyg.viewer.components.Column': 'column',
  'wysiwyg.viewer.components.StripColumnsContainer': 'stripSvg',
  'wysiwyg.viewer.components.HoverBox': 'hoverBox',
  'wysiwyg.viewer.components.FormContainer': 'listBuilder',
  'tpa.viewer.components.Impress': 'galleries',
  'tpa.viewer.components.Accordion': 'galleries',
  'tpa.viewer.components.StripShowcase': 'galleries',
  'wysiwyg.viewer.components.BoxSlideShow': 'slideShow',
  'wysiwyg.viewer.components.StateBox': 'stateBox',
  'wysiwyg.viewer.components.StateBoxState': 'container',
  'wysiwyg.viewer.components.StateBoxFormState': 'container',
  'wysiwyg.viewer.components.StateStrip': 'stateStrip',
  'wysiwyg.viewer.components.BackToTopButton': 'backToTopButtonIcon',
  'wysiwyg.viewer.components.ExpandableMenu': 'verticalContainerIcon',
  'wysiwyg.viewer.components.MenuToggle': 'tinyMenu',
  'wysiwyg.viewer.components.inputs.CheckboxGroup': 'checkboxGroup',
  'wixui.AddressInput': 'addressInput',
  'wixui.ProgressBar': 'progressBar',
  'wixui.SelectionTagsList': 'selectionTags',
  'wixui.StylableHorizontalMenu': 'horizontalMenu',
  [constants.COMP_TYPES.SECTION]: 'ClassicSection',
  'wixui.CollapsibleText': 'text',
  'wixui.LottieEmbed': 'lottie',
  'wixui.Tabs': 'tabs',
  'wixui.AccordionContainer': toWBU('Accordion'),
  'wixui.StylableButton': 'button',
  'dsgnsys.WutAvatarGroup': 'avatar',
  'dsgnsys.WutAvatar': 'avatar',
  'dsgnsys.WutBadge': 'badge',
  'wixui.ShareButtons': 'shareButtons',
  'wixui.SelectableContainerInput': 'selectableContainerInput',
  'wysiwyg.viewer.components.RefComponent': 'widgetLayerIcon',
};

export function getSvgNameForComp(
  editorAPI: EditorAPI,
  compType: string,
  compRef: CompRef,
  allowWBUIconsByOrigin: boolean,
): string {
  const iconsList =
    wbuIconsEnabled && allowWBUIconsByOrigin ? wbuIconsList : localIconsList;
  const iconValueRaw = iconsList[compType as keyof typeof iconsList];
  const iconValue =
    typeof iconValueRaw === 'function'
      ? iconValueRaw(editorAPI, compRef)
      : iconValueRaw;

  return iconValue || iconsList.default;
}
