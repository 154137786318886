import _ from 'lodash';
import type {
  DocumentServicesCreator,
  DSAction,
  DSRead,
} from 'types/documentServices';
import * as util from '@/util';
import experiment from 'experiment';
import { wrapAPIFunctions } from '@wix/api-debug-tools';
import {
  createDSDebugger,
  reportPathCallToConsole,
  handlePathCallError,
} from './dsDebugTools';
import { createValidateDSActionsMethodArguments } from './dsDebugTools/dsActionsValidators';

export function createDocumentServices(
  documentServices: DocumentServicesCreator,
): {
  dsRead: DSRead;
  dsActions: DSAction;
  documentServices: DocumentServicesCreator;
} {
  const dsRead = _.merge(
    documentServices.getConstants(),
    documentServices.getReadMethods(),
  );
  const dsActions = documentServices.getActions();

  dsRead.deprecatedOldBadPerformanceApis =
    dsRead.deprecatedOldBadPerformanceApis || dsRead;

  // eslint-disable-next-line @wix/santa/no-falsy-experiment
  if (!experiment.isOpen('se_wrapDS')) {
    return {
      dsRead,
      dsActions,
      documentServices,
    };
  }

  type QueryDebugOption = 'dsread' | 'dsactions';
  const debugOptions: QueryDebugOption[] = util.url
    .getParameterByName('debug')
    ?.toLowerCase()
    .split(',');
  const shouldDebugDSRead = debugOptions?.includes('dsread');
  const shouldDebugDSActions = debugOptions?.includes('dsactions');

  let dsDebugger: ReturnType<typeof createDSDebugger>;

  if (shouldDebugDSRead || shouldDebugDSActions) {
    dsDebugger = createDSDebugger();
    // @ts-expect-error
    window.__dsDebugger = dsDebugger;
  }

  const validateDSActionMethodArguments = shouldDebugDSActions
    ? createValidateDSActionsMethodArguments()
    : null;

  const callAndReport = (
    thisArg: any,
    method: Function,
    args: IArguments,
    path: string[],
  ) => {
    const start = performance.now();
    const result = method.apply(thisArg, args);
    dsDebugger.reportPathCall(path, {
      duration: performance.now() - start,
    });
    return result;
  };

  const dsReadWrapped = wrapAPIFunctions(dsRead, {
    path: ['dsRead'],
    wrap(originalFunction, path) {
      return function (this: unknown) {
        try {
          if (shouldDebugDSRead) {
            return callAndReport(this, originalFunction, arguments, path);
          }
          return originalFunction.apply(this, arguments);
        } catch (e) {
          handlePathCallError(e, path);
        }
      } as unknown as typeof originalFunction;
    },
  });

  const dsActionsWrapped = wrapAPIFunctions(dsActions, {
    path: ['dsActions'],
    wrap(originalFunction, path) {
      return function (this: unknown) {
        try {
          if (shouldDebugDSActions) {
            const argumentsErrors = validateDSActionMethodArguments(
              path.join('.'),
              arguments,
            );
            reportPathCallToConsole(path, arguments, argumentsErrors);

            return callAndReport(this, originalFunction, arguments, path);
          }
          return originalFunction.apply(this, arguments);
        } catch (e) {
          handlePathCallError(e, path);
        }
      } as unknown as typeof originalFunction;
    },
  });

  return {
    dsRead: dsReadWrapped,
    dsActions: dsActionsWrapped,
    documentServices: _.merge(_.cloneDeep(dsReadWrapped), dsActionsWrapped),
  };
}
