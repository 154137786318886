import UrlBuilder from '../url/UrlBuilder';
import serviceTopology from '../serviceTopology/serviceTopology';

import type { EditorAPI } from '@/editorAPI';

const PREMIUM_URL = `/apps/upgrade`;
const PREMIUM_SITE_BASE_URL = 'https://www.wix.com/store/plans';

export const getPremiumUrl = (
  editorAPI: EditorAPI,
  {
    appDefinitionId,
    origin,
    referralAdditionalInfo,
  }: {
    appDefinitionId: string;
    origin?: string;
    referralAdditionalInfo?: string;
  },
) => {
  return new UrlBuilder(serviceTopology.businessManagerDomain + PREMIUM_URL)
    .param('appDefId', appDefinitionId)
    .param('metaSiteId', editorAPI.dsRead.generalInfo.getMetaSiteId())
    .ifParam(!!origin, 'pp_origin', origin)
    .ifParam(
      !!referralAdditionalInfo,
      'referralAdditionalInfo',
      referralAdditionalInfo,
    )
    .url();
};

export const getPremiumUrlForSiteUpgrade = (
  editorAPI: EditorAPI,
  {
    appDefinitionId,
    origin,
    referralAdditionalInfo,
  }: {
    appDefinitionId: string;
    origin?: string;
    referralAdditionalInfo?: string;
  },
) => {
  return new UrlBuilder(PREMIUM_SITE_BASE_URL)
    .param('appDefId', appDefinitionId)
    .param('siteGuid', editorAPI.dsRead.generalInfo.getMetaSiteId())
    .ifParam(!!origin, 'pp_origin', origin)
    .ifParam(
      !!referralAdditionalInfo,
      'referralAdditionalInfo',
      referralAdditionalInfo,
    )
    .url();
};
