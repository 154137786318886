// @ts-nocheck
import _ from 'lodash';
import serviceTopology from '../serviceTopology/serviceTopology';
import constants from './constants';
import * as utils from '@wix/santa-editor-utils';
import { fontUtils } from '@/util';

function getDownloadLink(fontObj) {
  const ext = fontObj?.fileInput?.format;
  if (ext) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(constants.CONVERTED_EXTENTIONS, ext)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const fileOutput = _.find(fontObj.fileOutput.font, { format: ext });
      const url = fileOutput.url || fontObj.fileUrl;
      return utils.url.joinURL(serviceTopology.mediaRootUrl, url);
    }
    return utils.url.joinURL(serviceTopology.mediaRootUrl, fontObj.fileUrl);
  }
}

function getUploadedFontData(fontObj) {
  const result = _.clone(fontObj);
  const originalFontName =
    result.fileInput &&
    result.fileInput.font_name.replace(/[^\w\s]/gi, '').toLowerCase();

  result.fileName = result.name.split('.')[0].replace(/[-_]/g, ' ');
  result.displayName = _.startCase(result.fileInput.font_name);
  result.fontFamily = `${fontUtils.getUploadedFontValue(result.id)},${
    constants.ORIGINAL_FONT_PREFIX
  }${_.snakeCase(originalFontName)}`;
  result.cssFontFamily = result.fontFamily;
  result.fontFace = fontUtils.getUploadedFontFaceStyles(
    [result.fontFamily],
    serviceTopology.mediaRootUrl,
  );
  result.downloadUrl = getDownloadLink(fontObj);

  return result;
}

function isFontExt(file, shouldFilterOtf) {
  const extensions = shouldFilterOtf
    ? constants.CONVERTED_EXTENTIONS
    : constants.ACCEPTABLE_EXTENTIONS;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/last, you-dont-need-lodash-underscore/split
  const ext = _.last(_.split(file.name, '.'));
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/to-lower, you-dont-need-lodash-underscore/includes
  return _.includes(extensions, _.toLower(ext));
}

function isFolder(file) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  return !_.includes(file.name, '.');
}

function isLargeFile(file) {
  return file.size > constants.MAX_FILE_SIZE;
}

function shouldFilterOtfFiles(language, geoLocation) {
  return (
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(constants.CJK_LANGUAGES, language) ||
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(constants.CJK_COUNTRIES, geoLocation)
  );
}

function getErrorMessage(errorCode) {
  return constants.FONT_ERROR[errorCode] || constants.FONT_ERROR.unknown;
}

export {
  getUploadedFontData,
  getErrorMessage,
  shouldFilterOtfFiles,
  isFolder,
  isFontExt,
  isLargeFile,
  constants,
};
