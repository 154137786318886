import type { dragBox as DragBox, DraggableItem } from '@/addPanelInfra';
import type { EditorAPI } from '@/editorAPI';
import type { MouseMoveAction } from '@/rEditor';
import * as santaEditorUtils from '@wix/santa-editor-utils';
import _ from 'lodash';
import React, { type MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import type { MapStateToProps } from 'types/redux';

interface WithDragToStageMapStateToProps {
  _mouseActions: EditorAPI['mouseActions'];
}

interface WithDragToStageProps extends WithDragToStageMapStateToProps {}

export interface WithStartItemDrag {
  startItemDrag: (event: MouseEvent, dragItemData: DraggableItem) => void;
}

interface WithDragToStageState {
  isDragging: boolean;
}

const {
  connect,
  STORES: { EDITOR_API },
} = santaEditorUtils.hoc;

const mapStateToProps: MapStateToProps<WithDragToStageMapStateToProps, any> = ({
  editorAPI,
}): WithDragToStageMapStateToProps => ({
  _mouseActions: editorAPI.mouseActions,
});

const withDragToStage =
  <P extends object>(
    addPanelDragToStage: MouseMoveAction,
    dragBox: typeof DragBox | React.FC<any>,
  ) =>
  (
    Component: React.ComponentType<
      P & WithStartItemDrag & Omit<WithDragToStageProps, '_mouseActions'>
    >,
  ): React.ComponentType<P> => {
    class WithDragToStage extends React.Component<
      P & WithDragToStageProps,
      WithDragToStageState
    > {
      constructor(props: WithDragToStageProps & P) {
        super(props);

        this.state = { isDragging: false };

        this.onStopItemDrag = this.onStopItemDrag.bind(this);
        this.onStartItemDrag = this.onStartItemDrag.bind(this);
        this.startItemDrag = this.startItemDrag.bind(this);
      }

      startItemDrag(event: MouseEvent, dragItemData: DraggableItem) {
        const { _mouseActions } = this.props;

        if (event.button === 2) {
          // right click
          return;
        }

        dragItemData.rect = _.transform(
          dragItemData.rect,
          (result: AnyFixMe, val: number, key) => {
            result[key] = Math.floor(val);
          },
        );

        const dragParams = {
          item: dragItemData,
          dragBoxClass: dragItemData.overrideDragBoxEl || dragBox,
          panelElm: ReactDOM.findDOMNode(this),
          evt: event,
          onItemDragEnd: this.onStopItemDrag,
          onItemDragStart: this.onStartItemDrag,
        };

        _mouseActions.registerMouseMoveAction(addPanelDragToStage, dragParams);
      }

      onStopItemDrag() {
        this.setState({ isDragging: false });
      }

      onStartItemDrag() {
        if (!this.state.isDragging) {
          this.setState({ isDragging: true });
        }
      }

      render() {
        const { props } = this;

        return React.createElement(
          Component,
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign<
            object,
            P & Omit<WithDragToStageProps, '_mouseActions'>,
            WithStartItemDrag
          >(
            {},
            _.omit<P & WithDragToStageProps, '_mouseActions'>(props, [
              '_mouseActions',
            ]) as P & Omit<WithDragToStageProps, '_mouseActions'>,
            {
              startItemDrag: this.startItemDrag,
            },
          ),
        );
      }
    }

    return connect(EDITOR_API, mapStateToProps, () => ({}))(
      WithDragToStage as any,
    );
  };

export default withDragToStage;
