import type { EditorAPI } from '@/editorAPI';
import type { BIErrorDefinition } from '@wix/document-services-types';
import type { BiEventFields } from 'types/bi';
import constants, { type BackgroundData, type VideoData } from '@/constants';
import { imageTransform } from '@wix/santa-editor-utils';
const {
  MEDIA_TYPES: { VIDEO },
} = constants;

const { alignTypes, fittingTypes } = imageTransform;

interface BackgroundBiParams {
  origin: string;
  componentId: string;
  presetIndex: number;
  panelName: string;
  isChanged?: boolean;
  isAiGenerator?: boolean;
  source?: string;
}

function changedBackgroundBiEvent(
  editorAPI: EditorAPI,
  eventDef: any,
  data: BackgroundData,
  extraParams: BackgroundBiParams,
) {
  if (!extraParams.origin) {
    //probably no change
    return;
  }

  const pageId = editorAPI.dsRead.pages.getPrimaryPageId();
  const currPageId = editorAPI.dsRead.pages.getFocusedPageId();
  let bg_media_name, bg_video_loop;
  let bg_mobile_detached;
  const media = data.mediaRef;
  const { origin, presetIndex, isAiGenerator } = extraParams;
  //get media url (image or video)
  if (media) {
    bg_media_name =
      media.type === VIDEO ? (media as VideoData).videoId : media.uri;
    //get video loop value
    if (typeof (media as VideoData).loop !== 'undefined') {
      bg_video_loop = (!!(media as VideoData).loop).toString();
    }
  }
  //check if mobile data is detached
  if (
    editorAPI.isMobileEditor() ||
    !editorAPI.dsRead.pages.background.get(pageId, 'mobile').isPreset
  ) {
    bg_mobile_detached = true;
  }
  editorAPI.bi.event(eventDef, {
    origin: extraParams.origin,
    bg_media_type: media?.type || 'color',
    bg_mobile_detached: (!!bg_mobile_detached).toString(),
    bg_media_name,
    bg_page_id: pageId,
    bg_preset_index: origin === 'bg_presets' ? presetIndex : -1,
    bg_vendor_name: data?.mediaRef?.artist?.name,
    bg_vendor_media_id: data?.mediaRef?.artist?.id,
    bg_color: data.color,
    bg_fitting: data.fittingType || fittingTypes.SCALE_TO_FILL,
    bg_positioning: data.alignType || alignTypes.CENTER,
    bg_image_opacity:
      data.mediaRef?.opacity && Math.floor(data.mediaRef.opacity * 100),
    bg_scroll_with_site: data.scrollType || 'none',
    bg_overlay_color: data.colorOverlay,
    bg_overlay_color_opacity: Math.floor((data.colorOverlayOpacity || 1) * 100),
    bg_video_overlay_pattern: (data?.mediaRef as VideoData)?.posterImageRef
      ?.uri,
    bg_video_loop,
    bg_slideshow_transition_type: '',
    bg_slideshow_transition_duration: -1,
    bg_slideshow_transition_direction: '',
    bg_slideshow_pausebetweenimages: -1,
    page_id: currPageId,
    is_ai_generator: isAiGenerator,
  });
}

function changedStripBackground(
  editorAPI: EditorAPI,
  eventDef: BiEventFields,
  data: BackgroundData,
  extraParams: BackgroundBiParams,
) {
  if (!extraParams.isChanged) {
    // no change
    return;
  }
  editorAPI.bi.event(
    eventDef,
    getBackgroundBiParams(editorAPI, data, extraParams),
  );
}

function getBackgroundBiParams(
  editorAPI: EditorAPI,
  data: BackgroundData,
  extraParams?: BackgroundBiParams,
) {
  const pageId = editorAPI.dsRead.pages.getPrimaryPageId();
  let bg_mobile_detached;
  const media = data.mediaRef;
  const { origin, componentId, presetIndex, panelName, source, isAiGenerator } =
    extraParams || {};
  //check if mobile data is detached
  if (
    editorAPI.isMobileEditor() ||
    !editorAPI.dsRead.pages.background.get(pageId, 'mobile').isPreset
  ) {
    bg_mobile_detached = true;
  }
  const colorLayer = {
    color: data.color,
    opacity: data.colorOpacity !== undefined ? data.colorOpacity : 1,
  };
  if (data.colorLayers?.length) {
    const fillColor = data.colorLayers[0].fill;
    colorLayer.opacity = data.colorLayers[0].opacity;
    if (fillColor.type === 'SolidColor') {
      colorLayer.color = fillColor.color;
    } else {
      colorLayer.color = JSON.stringify(fillColor.gradients || fillColor);
    }
  }
  // todo: add focal point data
  return {
    panel_name: panelName,
    origin,
    source,
    bg_media_type: media?.type || 'color',
    bg_mobile_detached: (!!bg_mobile_detached).toString(),
    bg_media_name: media?.uri || (media as VideoData)?.videoId || '',
    bg_page_id: pageId,
    bg_preset_index: origin === 'bg_presets' ? presetIndex : -1,
    bg_vendor_name: media?.artist?.name,
    bg_vendor_media_id: media?.artist?.id,
    bg_color: colorLayer.color,
    bg_color_opacity: colorLayer.opacity,
    bg_fitting: data.fittingType || fittingTypes.SCALE_TO_FILL,
    bg_positioning: data.alignType || alignTypes.CENTER,
    bg_image_opacity:
      media?.type === 'Image' ? Math.floor((media.opacity ?? 1) * 100) : 100,
    bg_scroll_with_site: data.scrollType || 'none',
    bg_overlay_color: data.colorOverlay || '',
    bg_overlay_color_opacity: Math.floor((data.colorOverlayOpacity || 1) * 100),
    bg_video_overlay_pattern: data?.imageOverlay?.uri || 'none',
    bg_video_opacity:
      media?.type === 'WixVideo' ? Math.floor((media.opacity ?? 1) * 100) : 100,
    bg_video_loop: (media as VideoData)?.loop?.toString() || 'true',
    bg_video_speed: (media as VideoData)?.playbackSpeed || 1,
    bg_slideshow_transition_type: '',
    bg_slideshow_transition_duration: -1,
    bg_slideshow_transition_direction: '',
    bg_slideshow_pausebetweenimages: -1,
    component_id: componentId,
    is_ai_generator: isAiGenerator,
  };
}

function applyToOtherClick(editorAPI: EditorAPI, eventDef: BiEventFields) {
  editorAPI.bi.event(eventDef);
}

function applyToOtherEvent(
  editorAPI: EditorAPI,
  eventDef: BiEventFields,
  params: any,
) {
  editorAPI.bi.event(eventDef, params);
}

function mobileDataDuplicationError(
  editorAPI: EditorAPI,
  eventDef: BIErrorDefinition,
) {
  editorAPI.dsActions.bi.error(eventDef);
}

export {
  changedBackgroundBiEvent,
  changedStripBackground,
  getBackgroundBiParams,
  applyToOtherClick,
  applyToOtherEvent,
  mobileDataDuplicationError,
};
