import { translate } from '@/i18n';
import constants from '@/constants';

import type { CompStructure } from 'types/documentServices';

const BLANK_SECTION_LABEL = 'section_name_blank';

export const getBlankSectionStructure = ({
  height,
  y,
  name,
  design,
}: {
  height: number;
  y: number;
  name?: string;
  design?: Object;
}): CompStructure => {
  return {
    anchor: {
      name: name || translate(BLANK_SECTION_LABEL),
      type: 'AnchorInfo',
    } as CompStructure['anchor'],
    design: {
      type: 'MediaContainerDesignData',
      background: {
        mediaRef: null,
        fittingType: 'fill',
        alignType: 'center',
        colorOverlay: '',
        colorOverlayOpacity: 1,
        color: 'color_11',
        colorOpacity: 0,
        type: 'BackgroundMedia',
        imageOverlay: '',
        scrollType: 'none',
      },
      ...design,
    },
    components: [],
    componentType: constants.COMP_TYPES.SECTION,
    layout: {
      x: 0,
      y,
      scale: 1,
      height,
      rotationInDegrees: 0,
      width: 980,
    },
    type: 'Container',
    props: {
      type: 'ClassicSectionProperties',
    },
  };
};
