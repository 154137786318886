import { translate } from '@/i18n';

const translateIfNeeded = (
  key: string,
  options?: Record<string, string>,
): string => {
  const translated = translate(key, options);
  return translated.startsWith('!') ? key : translated;
};

export { translateIfNeeded };
