import { BaseEntryScope, createEntryPoint } from '@/apilib';
import {
  DocumentServicesApiKey,
  InitDocumentServicesApiKey,
  EditorCoreApiKey,
} from '@/apis';
import { InitDocumentServicesPublicApi } from './api';

export class Scope extends BaseEntryScope {
  core = this.useDependency(EditorCoreApiKey);
}

export const DocumentServicesEntry = createEntryPoint({
  Scope,
  layer: 'SITE',
  name: 'Document Services entry',
  initialize(scope) {
    scope.core.hooks._dsIsReady.promise.then(({ dsRead }) => {
      dsRead.scopes.metaData.loadMetaData();
    });
  },
  publicApi({ contributeApi, declareApi }) {
    declareApi(DocumentServicesApiKey);
    contributeApi(InitDocumentServicesApiKey, InitDocumentServicesPublicApi);
  },
});
