import _ from 'lodash';
import constants from '@/constants';
import experiment from 'experiment';
import { isResponsiveEditor, overridable } from '@wix/santa-editor-utils';
import { isNewWorkspaceEnabled } from '../workspace';
import type { CompData, CompStructure } from 'types/documentServices';
import type { PanelProps, StageLayout } from '@/stateManagement';
import { fixedStage, uiUtils } from '@/util';

const { PANEL_SIZE_TO_KEEP_IN_VIEW } = constants.PANELS;
const { MOBILE_PREVIEW_TOP_NEW_WORKSPACE } = constants.UI;
// constant value contains diff of sizes for stage between mobile and desktop
// layout, because on mobile we have mobile frame the the coordinates for top
// limit is grater than for desktop 148 for mobile and 88 for desktop look in
// stateManagement/domMeasurements stageLayout
const DIFF_OF_STAGE_TOP_SIZE_FOR_MOBILE_OLD_WORKSPACE = 60;
const DIFF_OF_STAGE_TOP_SIZE_FOR_MOBILE_NEW_WORKSPACE =
  MOBILE_PREVIEW_TOP_NEW_WORKSPACE;
const STAGE_MARGIN = 10;

function getValueByKey(key: AnyFixMe, data: AnyFixMe) {
  return data && _.get(data, key);
}

export interface FrameProps {
  panelName: string;
  style: React.CSSProperties;
  onCompPanelLoaded: () => void;
  onBackClick: () => void;
  backButtonLabel: string;
  shouldTranslate: boolean;
  onDragEnd: () => void;
  overrideTitle: string;
}

function getFrameProps(props: PanelProps) {
  return _.pick(props, [
    'panelName',
    'style',
    'onCompPanelLoaded',
    'onBackClick',
    'backButtonLabel',
    'shouldTranslate',
    'onDragEnd',
    'overrideTitle',
    'configuration',
  ]);
}

function getData(
  fieldName: AnyFixMe,
  compData: CompData,
  compDesign: CompStructure['design'],
) {
  let data = getValueByKey(fieldName, compData);
  // Patch to allow opening the designData experiment in viewer without the editor knowing about it
  // If data is missing on the document_data map we try finding it on the design_data
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(data) && !_.isUndefined(compDesign)) {
    data = getValueByKey(fieldName, compDesign);
  }
  return data;
}

function getValue(fieldName: AnyFixMe, compInfo: AnyFixMe) {
  return getValueByKey(fieldName, compInfo);
}

/**
 * Used by GFPP panels to limitate coordinates where it can be moved
 *
 * @param panelHeight
 * @param panelWidth
 * @param stageLayout
 * @param editorContentLayout
 * @param isMobile
 */
const getPanelDragLimits = overridable(
  (
    panelHeight: number,
    panelWidth: number,
    stageLayout: StageLayout,
    editorContentLayout: StageLayout,
    isMobile: boolean = false,
  ) => {
    const isFixedStageEnabled = fixedStage.isFixedStageEnabled();

    const DIFF_OF_STAGE_TOP_SIZE_FOR_MOBILE = isNewWorkspaceEnabled()
      ? DIFF_OF_STAGE_TOP_SIZE_FOR_MOBILE_NEW_WORKSPACE
      : DIFF_OF_STAGE_TOP_SIZE_FOR_MOBILE_OLD_WORKSPACE;

    const DESKTOP_MIN_Y = isFixedStageEnabled
      ? stageLayout.top - constants.UI.FIXED_STAGE_MARGIN_TOP
      : stageLayout.top;

    const MOBILE_MIN_Y = isFixedStageEnabled
      ? DESKTOP_MIN_Y
      : stageLayout.top - DIFF_OF_STAGE_TOP_SIZE_FOR_MOBILE;

    const limitBottom =
      editorContentLayout.bottom + panelHeight - PANEL_SIZE_TO_KEEP_IN_VIEW;

    const stageLayoutTop = isMobile ? MOBILE_MIN_Y : DESKTOP_MIN_Y;

    const baseLeftForLimitLeft = isFixedStageEnabled
      ? stageLayout.x
      : stageLayout.left;

    const limitLeft = isNewWorkspaceEnabled()
      ? baseLeftForLimitLeft + STAGE_MARGIN
      : stageLayout.left - panelWidth + PANEL_SIZE_TO_KEEP_IN_VIEW;
    const limitRight = isFixedStageEnabled
      ? editorContentLayout.right - STAGE_MARGIN - uiUtils.getScrollbarWidth()
      : stageLayout.right + panelWidth - PANEL_SIZE_TO_KEEP_IN_VIEW;

    return {
      x: [limitLeft, limitRight],
      y: [stageLayoutTop + STAGE_MARGIN, limitBottom],
    };
  },
);

const isNewLinkPanelAnchorsFlowEnabled = (): boolean => !isResponsiveEditor();

const isLinkingOfNewLightboxEnabled = (): boolean =>
  !isResponsiveEditor() && experiment.isOpen('se_linkPanelsAddNewLightbox');

export {
  getFrameProps,
  getPanelDragLimits,
  getData,
  getValue,
  isNewLinkPanelAnchorsFlowEnabled,
  isLinkingOfNewLightboxEnabled,
};
