import experiment from 'experiment';
import { editorSessionId } from '../../editorSessionUtil/editorSessionUtil';
import { biLogger, promiseUtils } from '@/util';
import { glassBoxRecordStarted } from '@wix/bi-logger-editor/v2';
import { ErrorReporter } from '@wix/editor-error-reporter';

import type { EditorParams } from '@/editorParams';

/**
 * @see https://github.com/wix-private/fed-infra/tree/master/wix-recorder
 * */
export interface WixRecorder {
  addLabel(label: string): Promise<void>;
  withExperiments(specs: { [specName: string]: string }): Promise<void>;
  addCustomAttribute(key: string, value: string | string[]): Promise<void>;
  recordUrl(): Promise<string>;
  __forceRecording(): Promise<void>;
}

export interface EditorWixRecorder {
  addLabel(label: string): void;
  addCustomAttribute(key: string, value: string | string[]): void;
  getRecordUrl(): Promise<string> | undefined;
  start(): void;
  forceRecordIfNeeded(editorParams: EditorParams): void;
}

/**
 * Check if window.wixRecorder exists every time, since the recording experiment can be closed
 * @see https://wix.slack.com/archives/C010TKJ9FPF/p1586251479047900
 * */

const addLabel = (label: string): void => {
  window.wixRecorder?.addLabel(label);
};

const addCustomAttribute = (key: string, value: string | string[]): void => {
  window.wixRecorder?.addCustomAttribute(key, value);
};

const withExperiments = (experiments: { [spec: string]: string }) => {
  window.wixRecorder.withExperiments(experiments);
};

const getRecordUrl = (): Promise<string> | undefined => {
  return window.wixRecorder?.recordUrl();
};

const addSentryRecordingUrl = (sessionRecordUrl: string) => {
  ErrorReporter._addEventProcessor((event) => {
    event.tags = {
      ...event.tags,
      hasSessionRecordingUrl: true,
    };
    event.extra.recordingUrl = sessionRecordUrl;
    return event;
  });
};

const start = async (): Promise<void> => {
  if (!window.wixRecorder) return;

  addLabel('santa-editor');
  addCustomAttribute('editorSessionId', editorSessionId);
  withExperiments(experiment.getRunningExperiments());

  // It takes time for FullStory URL to init, we want to wait before we check url
  await promiseUtils.waitFor(3000);

  const sessionRecordUrl = await getRecordUrl();
  if (!sessionRecordUrl) return;

  addSentryRecordingUrl(sessionRecordUrl);
  biLogger.report(
    glassBoxRecordStarted({
      videoUrl: sessionRecordUrl,
    }),
  );
};

const forceRecordIfNeeded = async (editorParams: EditorParams) => {
  const { isDebug, isQA, siteCreationWizard, siteGenerationWizard } =
    editorParams;
  const isDev = isDebug || isQA;
  const siteCreationMonitoredFlow =
    experiment.isOpen('se_siteCreationTemplateForceRecording') &&
    siteCreationWizard;

  const siteGenerationMonitoredFlow =
    experiment.isOpen('se_siteGenerationForceRecording') &&
    siteGenerationWizard;

  if (isDev || !siteCreationMonitoredFlow || !siteGenerationMonitoredFlow)
    return;

  try {
    await window.wixRecorder?.__forceRecording();
  } catch (e) {
    console.log('wixRecorder force recording error:', e);
  }
};

export const editorWixRecorder: EditorWixRecorder = {
  addLabel,
  addCustomAttribute,
  getRecordUrl,
  start,
  forceRecordIfNeeded,
};
