// @ts-nocheck
import * as util from '@/util';

function fetchJson(url, onSuccess, onError) {
  util.http.fetchJson(url).then(onSuccess).catch(onError);
}

// function getVideoUrl(videoId) {
//     return '//www.facebook.com/plugins/video.php?href=https://www.facebook.com/' + videoId;
// }

function getVideoUrl(videoId) {
  return `//www.facebook.com/${videoId}`;
}

function getVideoIdFromUrl(url) {
  const urlRegex = /facebook\.com\/(.+\/videos\/[A-Za-z0-9._%-]*)/gi;
  const match = urlRegex.exec(url);
  if (match?.length && match[1]) {
    //if there is a match, the second group is the id we want
    return match[1];
  }
}

function verifyVideoUrl(url, onSuccess, onError) {
  const videoId = getVideoIdFromUrl(url);
  if (!videoId) {
    onError();
  }
  const dataUrl = `//www.facebook.com/plugins/video.php?href=https://www.facebook.com/${videoId}`;
  fetchJson(dataUrl, onSuccess, onError);
}

export { verifyVideoUrl, getVideoUrl, getVideoIdFromUrl };
