import type { PublicSlotKey } from '@/apilib';
import type { DocumentServicesObject } from 'types/documentServices';
import type { InitDocumentServicesPublicApi } from './api';

export const DocumentServicesApiKey: PublicSlotKey<DocumentServicesObject> = {
  name: 'Document Services API', //same as in editorx and used by platform
  layer: 'SITE',
  public: true,
};

export const InitDocumentServicesApiKey: PublicSlotKey<InitDocumentServicesPublicApi> =
  {
    name: 'Document Services init api',
    layer: 'SITE',
    public: true,
  };
