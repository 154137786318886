import constants from '../base/baseConstants';
import addPanelConsts from '@/addPanelDataConsts';

const categories = addPanelConsts.CATEGORIES_ID;

export default {
  TOP_BAR: {
    QUICK_NAVIGATION: {
      KEY: 'quickNavigation',
      IS_HIDDEN: 'isHidden',
    },
    BUTTONS: {
      SAVE: {
        KEY: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
        LABEL: 'label',
        CLASS_NAME: 'className',
        DROP_PANEL_DATA: 'dropPanelData',
      },
      PUBLISH: {
        KEY: constants.ROOT_COMPS.TOPBAR.BUTTONS.PUBLISH,
        LABEL: 'label',
        CLASS_NAME: 'className',
        DROP_PANEL_DATA: 'dropPanelData',
      },
      PREVIEW: {
        KEY: constants.ROOT_COMPS.TOPBAR.BUTTONS.PREVIEW,
        LABEL: 'label',
        CLASS_NAME: 'className',
        TOOL_TIP_DATA: 'tooltipData',
      },
      MOBILE_MODE: {
        KEY: constants.ROOT_COMPS.TOPBAR.BUTTONS.MOBILE_MODE,
        LABEL: 'label',
        CLASS_NAME: 'className',
        TOOL_TIP_DATA: 'tooltipData',
      },
      DESKTOP_MODE: {
        KEY: constants.ROOT_COMPS.TOPBAR.BUTTONS.DESKTOP_MODE,
        LABEL: 'label',
        CLASS_NAME: 'className',
        TOOL_TIP_DATA: 'tooltipData',
      },
      ZOOM_OUT: {
        KEY: constants.ROOT_COMPS.TOPBAR.BUTTONS.ZOOM,
        CLASS_NAME: 'className',
      },
      BACK_TO_EDITOR: {
        KEY: constants.ROOT_COMPS.TOPBAR.BUTTONS.BACK_TO_EDITOR,
        LABEL: 'label',
      },
      LOCAL_PUSH: {
        KEY: constants.ROOT_COMPS.TOPBAR.BUTTONS.LOCAL_PUSH,
        LABEL: 'label',
        CLASS_NAME: 'className',
        DROP_PANEL_DATA: 'dropPanelData',
        TOOL_TIP_DATA: 'tooltipData',
      },
    },
    MENUS: {},
    MENU_ITEMS: {
      TOOLS_PROPERTIES_PANEL: {
        KEY: constants.ROOT_COMPS.TOPBAR.TOOLS_MENU_ITEMS.PROPERTIES_TOOLBAR,
        SHOULD_OPEN_AS_SECTION: 'shouldOpenAsSection',
      },
      TOOLS_WIDGET_PUBLIC_API: {
        KEY: constants.ROOT_COMPS.TOPBAR.TOOLS_MENU_ITEMS.WIDGET_PUBLIC_API,
        IS_HIDDEN: 'isHidden',
      },
      HELP_TERMS_OF_USE: {
        KEY: constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_TERMS_OF_USE,
        LINK: 'link',
      },
      HELP_PRIVACY_POLICY: {
        KEY: constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_PRIVACY_POLICY,
        LINK: 'link',
      },
      CODE_TITLE: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.CODE_TITLE,
        OVERRIDE: 'override',
      },
      CODE_HEADER: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.CODE_HEADER,
        OVERRIDE: 'override',
      },
      CODE_SUB_HEADER: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.CODE_SUB_HEADER,
        OVERRIDE: 'override',
      },
      CODE_ON_LIST_ITEMS: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.CODE_ON_LIST_ITEMS,
        OVERRIDE: 'override',
      },
      CODE_SYMBOL: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.CODE_SYMBOL,
        OVERRIDE: 'override',
      },
      ENABLE_DEVELOPER_MODE: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.ENABLE_DEVELOPER_MODE,
        OVERRIDE: 'override',
      },
      HIRE_A_DEVELOPER: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.HIRE_A_DEVELOPER,
        LINK: 'link',
      },
      API_REFERENCES: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.API_REFERENCES,
        LINK: 'link',
      },
      VIDEO_TUTORIALS: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.VIDEO_TUTORIALS,
        LINK: 'link',
      },
      ADD_A_CODE_SNIPPET: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.ADD_A_CODE_SNIPPET,
        LINK: 'link',
      },
      EMBED_HTML: {
        KEY: constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.EMBED_HTML,
        OVERRIDE: 'override',
      },
    },
  },
  ADD_PANEL: {
    CATEGORIES: {
      GALLERY: {
        ID: categories.GALLERY,
        IS_HIDDEN: 'isHidden',
      },
      STRIP: {
        ID: categories.STRIP,
        IS_HIDDEN: 'isHidden',
      },
      LIGHTBOX: {
        ID: categories.POPUP,
        IS_HIDDEN: 'isHidden',
      },
      BLOG_WELCOME: {
        ID: categories.NEW_BLOG_WELCOME,
        IS_HIDDEN: 'isHidden',
      },
      BLOG: {
        ID: categories.NEW_BLOG,
        IS_HIDDEN: 'isHidden',
      },
      OLD_BLOG: {
        ID: categories.BLOG,
        IS_HIDDEN: 'isHidden',
      },
      STORE: {
        ID: categories.STORE,
        IS_HIDDEN: 'isHidden',
      },
      MEMBERS_WELCOME: {
        ID: categories.MEMBERS_WELCOME,
        IS_HIDDEN: 'isHidden',
      },
      MEMBERS: {
        ID: categories.MEMBERS,
        IS_HIDDEN: 'isHidden',
      },
      DATABASE: {
        ID: categories.DATABASE,
        IS_HIDDEN: 'isHidden',
      },
      MENU: {
        ID: categories.MENU,
        IS_HIDDEN: 'isHidden',
      },
      FORMS: {
        ID: categories.CONTACT,
        IS_HIDDEN: 'isHidden',
      },
      PAGE: {
        ID: categories.PAGE,
        IS_HIDDEN: 'isHidden',
      },
    },
    SECTIONS: {
      TEXT_THEMED_TEXT: {
        ID: 'textSECTIONS_TYPES_THEME0',
        IS_HIDDEN: 'isHidden',
      },
      TEXT_RELATED_APPS: {
        ID: 'textSECTIONS_TYPES_GENERAL3',
        IS_HIDDEN: 'isHidden',
      },
      IMAGE_RELATED_APPS: {
        ID: 'imageSECTIONS_TYPES_GENERAL3',
        IS_HIDDEN: 'isHidden',
      },
      GALLERIES: {
        ID: 'gallerySECTIONS_TYPES_PRESET0',
        IS_HIDDEN: 'isHidden',
      },
      MORE_GALLERIES: {
        ID: 'gallerySECTIONS_TYPES_PRESET3',
        IS_HIDDEN: 'isHidden',
      },
      GALLERY_RELATED_APPS: {
        ID: 'gallerySECTIONS_TYPES_GENERAL4',
        IS_HIDDEN: 'isHidden',
      },
      LISTS_AND_GRIDS_LISTS_PRESET0: {
        ID: 'repeaterSECTIONS_TYPES_PRESET0',
        IS_HIDDEN: 'isHidden',
      },
      LISTS_AND_GRIDS_LISTS_PRESET1: {
        ID: 'repeaterSECTIONS_TYPES_PRESET1',
        IS_HIDDEN: 'isHidden',
      },
      LISTS_AND_GRIDS_LISTS_PRESET2: {
        ID: 'repeaterSECTIONS_TYPES_PRESET2',
        IS_HIDDEN: 'isHidden',
      },
      VIDEO_WIX_VIDEO: {
        ID: 'videoSECTIONS_TYPES_PRESET1',
        IS_HIDDEN: 'isHidden',
      },
      VIDEO_RELATED_APPS: {
        ID: 'videoSECTIONS_TYPES_GENERAL3',
        IS_HIDDEN: 'isHidden',
      },
      SHAPE_RELATED_APPS: {
        ID: 'vectorShapeSECTIONS_TYPES_GENERAL6',
        IS_HIDDEN: 'isHidden',
      },
      MUSIC_WIX_MUSIC: {
        ID: 'musicSECTIONS_TYPES_PRESET2',
        IS_HIDDEN: 'isHidden',
      },
      MUSIC_SOUND_CLOUD: {
        ID: 'musicSECTIONS_TYPES_PRESET3',
        IS_HIDDEN: 'isHidden',
      },
      MUSIC_SPOTIFY: {
        ID: 'musicSECTIONS_TYPES_PRESET4',
        IS_HIDDEN: 'isHidden',
      },
      MUSIC_RELATED_APPS: {
        ID: 'musicSECTIONS_TYPES_GENERAL8',
        IS_HIDDEN: 'isHidden',
      },
      BUTTON_RELATED_APPS: {
        ID: 'buttonSECTIONS_TYPES_GENERAL5',
        IS_HIDDEN: 'isHidden',
      },
      SOCIAL_RELATED_APPS: {
        ID: 'socialSECTIONS_TYPES_GENERAL11',
        IS_HIDDEN: 'isHidden',
      },
      CONTACT_RELATED_APPS: {
        ID: 'contactSECTIONS_TYPES_GENERAL9',
        IS_HIDDEN: 'isHidden',
      },
      MORE_ANCHORS: {
        ID: 'otherSECTIONS_TYPES_PRESET2',
        IS_HIDDEN: 'isHidden',
      },
      MORE_ANCHORS_MENU: {
        ID: 'otherSECTIONS_TYPES_PRESET3',
        IS_HIDDEN: 'isHidden',
      },
      MORE_EVENTS: {
        ID: 'otherSECTIONS_TYPES_PRESET4',
        IS_HIDDEN: 'isHidden',
      },
      MORE_FULL_WIDTH_MAPS: {
        ID: 'otherSECTIONS_TYPES_PRESET7',
        IS_HIDDEN: 'isHidden',
      },
      MORE_RELATED_APPS: {
        ID: 'otherSECTIONS_TYPES_GENERAL9',
        IS_HIDDEN: 'isHidden',
      },
      MORE_RELATED_APPS_NOT_SU: {
        ID: 'otherSECTIONS_TYPES_GENERAL6',
        IS_HIDDEN: 'isHidden',
      },
      INTERACTIVE_FULL_WIDTH_SLIDESHOW: {
        ID: 'boxSlideShowSECTIONS_TYPES_PRESET0',
        IS_HIDDEN: 'isHidden',
      },
      POPULAR_SOCIAL_TOOLS_SECTION: {
        ID: 'socialSECTIONS_TYPES_PRESET2',
        SECTION_FOOTER_HIDDEN: 'sectionFooterHidden',
      },
    },
  },
  DESIGN_PANEL: {
    //todo change id after title+id refactor in add panel
    MORE_GALLERIES: {
      ID: 'SECTIONS_TYPES_PRESET2',
      IS_HIDDEN: 'isHidden',
    },
  },
  LEFT_BAR: {},
  LEFT_TREE: {
    WIDGETS_VIEW: {
      KEY: 'widgetsView',
      IS_HIDDEN: 'isHidden',
    },
    APP_BUILDER_LEFT_TREE_HEADER: {
      KEY: 'appBuilderLeftTreeHeader',
      IS_HIDDEN: 'isHidden',
    },
    TITLE: {
      KEY: 'title',
      LABEL: 'label',
    },
  },
  EXTERNAL_LINKS: {
    TERM_OF_USE: {
      KEY: 'termOfUse',
      LINK: 'link',
    },
    PRIVACY: {
      KEY: 'privacy',
      LINK: 'link',
    },
    LEARN: {
      KEY: 'learn',
      LINK: 'link',
    },
  },
};
