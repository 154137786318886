// @ts-nocheck
// DON'T USE FUNCTIONS FROM THIS FILE
// They are copied into @/theme package
// This file is only for compatibility with editorX
// and will be removed soon
import _ from 'lodash';
import type { RGBColorObject } from './types';

function realMod(value, mod) {
  const result = value > 0 ? value % mod : (mod + value) % mod;
  return result;
}

function colorComponentToHex(c = '') {
  const hex = c.toString(16);
  const result = hex.length === 1 ? `0${hex}` : hex;
  return result.toUpperCase();
}

/**
 * convert HSB color to RGB format
 * @deprecated Use the same method from @/theme package
 * @param hue - 0-360
 * @param s - 0-100
 * @param br - 0-100
 * @returns {*}
 */
function hsbToRgb(hsb): RGBColorObject {
  const { saturation } = hsb;
  const { brightness } = hsb;
  if (hsb.hue < 0 || hsb.hue > 360) {
    console.error('hue is out of range');
    return null;
  }
  const hue = hsb.hue % 360;
  const s = saturation / 100;
  const br = brightness / 100;
  let r, g, b;
  const c = s * br;
  const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
  const m = br - c;
  switch (Math.floor(hue / 60)) {
    case 0:
      r = c;
      g = x;
      b = 0;
      break;
    case 1:
      r = x;
      g = c;
      b = 0;
      break;
    case 2:
      r = 0;
      g = c;
      b = x;
      break;
    case 3:
      r = 0;
      g = x;
      b = c;
      break;
    case 4:
      r = x;
      g = 0;
      b = c;
      break;
    case 5:
      r = c;
      g = 0;
      b = x;
      break;
  }

  return {
    red: Math.round(255 * (r + m)),
    green: Math.round(255 * (g + m)),
    blue: Math.round(255 * (b + m)),
  };
}

/**
 * @deprecated Use the same method from @/theme package
 */
const hsbToHex = _.flow(hsbToRgb, rgbToHex);

/**
 *convert RGB color to HSB format
 * @deprecated Use the same method from @/theme package
 * @param red - 0-255
 * @param green - 0-255
 * @param blue - 0-255
 * @returns {{hue: number, saturation: number, brightness: number}}
 */
function rgbToHsbExact(rgb: RGBColorObject) {
  let hue;
  const { red } = rgb;
  const { green } = rgb;
  const { blue } = rgb;
  const r = red / 255;
  const g = green / 255;
  const b = blue / 255;
  const cMax = Math.max(r, g, b);
  const cMin = Math.min(r, g, b);
  const delta = cMax - cMin;
  if (delta === 0) {
    hue = 0;
  } else {
    switch (cMax) {
      case r:
        hue = 60 * realMod((g - b) / delta, 6);
        break;
      case g:
        hue = 60 * ((b - r) / delta + 2);
        break;
      case b:
        hue = 60 * ((r - g) / delta + 4);
        break;
    }
  }

  const saturation = cMax === 0 ? 0 : delta / cMax;
  const brightness = cMax;

  return {
    hue,
    saturation: 100 * saturation,
    brightness: 100 * brightness,
  };
}

/**
 *convert RGB color to HSB format
 * @deprecated Use the same method from @/theme package
 * @param red - 0-255
 * @param green - 0-255
 * @param blue - 0-255
 * @returns {{hue: number, saturation: number, brightness: number}}
 */
function rgbToHsb(rgb: RGBColorObject) {
  const hsbExact = rgbToHsbExact(rgb);
  return {
    hue: Math.round(hsbExact.hue),
    saturation: Math.round(hsbExact.saturation),
    brightness: Math.round(hsbExact.brightness),
  };
}

/**
 * @deprecated Use the same method from @/theme package
 */
function hsbToHsl(hsb) {
  const hue = hsb.hue % 360;
  const { saturation } = hsb;
  const { brightness } = hsb;
  const S = saturation / 100;
  const B = brightness / 100;
  const result = {
    hue,
    saturation: 0,
    lightness: 0,
  };

  const L = 0.5 * B * (2 - S);
  result.lightness = Math.round(100 * L);
  result.saturation = Math.round((100 * (B * S)) / (1 - Math.abs(2 * L - 1)));

  return result;
}

/**
 * @deprecated Use the same method from @/theme package
 */
function hslToHsb(hsl) {
  const { hue } = hsl;
  const { saturation } = hsl;
  const { lightness } = hsl;
  const S = saturation / 100;
  const L = lightness / 100;
  const result = {
    hue,
    saturation: 0,
    brightness: 0,
  };

  const B = 0.5 * (2 * L + S * (1 - Math.abs(2 * L - 1)));

  result.saturation = Math.round((100 * 2 * (B - L)) / B);
  result.brightness = Math.round(100 * B);

  return result;
}

/**
 * @deprecated Use the same method from @/theme package
 */
function rgbToHex(rgb: RGBColorObject) {
  return `#${colorComponentToHex(rgb.red)}${colorComponentToHex(
    rgb.green,
  )}${colorComponentToHex(rgb.blue)}`;
}

//reference: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
/**
 * @deprecated Use the same method from @/theme package
 */
function hexToRgb(hex): RGBColorObject {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
}

/**
 * @deprecated Use the same method from @/theme package
 */
const hexToHsb = _.flow(hexToRgb, rgbToHsb);

/**
 * @deprecated Use the same method from @/theme package
 */
const hexToHsbExact = _.flow(hexToRgb, rgbToHsbExact);

/**
 * @deprecated Use the same method from @/theme package
 */
const hslToHex = _.flow(hslToHsb, hsbToHex);

/**
 * @deprecated Use the same method from @/theme package
 */
function rgbStringToObject(rgbStr): RGBColorObject {
  const extractColorValsRegex = /rgb\((.+)\)/;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const colorVals = _.map(
    extractColorValsRegex.exec(rgbStr)[1].split(','),
    function (val) {
      return parseInt(val, 10);
    },
  );
  return _.zipObject(['red', 'green', 'blue'], colorVals);
}

/**
 * @deprecated Use the same method from @/theme package
 */
function rgbObjectToString(rgbaObj) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/values
  return `rgb(${_.values(rgbaObj).join(',')})`;
}

/**
 * @deprecated Use the same method from @/theme package
 */
function rgbaStringToObject(rgbaStr) {
  const extractColorValsRegex = /rgba\((.+)\)/;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const colorVals = _.map(
    extractColorValsRegex.exec(rgbaStr)[1].split(','),
    function (val) {
      return parseFloat(val, 10);
    },
  );
  return _.zipObject(['red', 'green', 'blue', 'alpha'], colorVals);
}

/**
 * @deprecated Use the same method from @/theme package
 */
function rgbaObjectToString(rgbaObj) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/values
  return `rgba(${_.values(rgbaObj).join(',')})`;
}

/**
 * @deprecated Use the same method from @/theme package
 * @param color
 * @return {number}
 */
function getDistanceToWhite(color) {
  if (!color) {
    return 100;
  }

  if (_.isString(color)) {
    color = hexToHsb(color);
  } else if (color.red) {
    color = rgbToHsb(color);
  } else if (color.lightness) {
    color = hslToHsb(color);
  }

  return Math.sqrt(
    Math.pow(100 - color.brightness, 2) + Math.pow(color.saturation, 2),
  );
}

/**
 * retrieve a 5-color group, with a root color as its median, and 4 other collated colors around it in the (brightness, saturation) plane
 * @deprecated Use the same method from @/theme package
 * @param rootColor the middle color in the group
 * @param {object} [paletteColors] the palette colors to maintain brightness order
 * @param {Array} [sequenceColorNames] the colorNames to access the palette colors so as to maintain brightness order
 * @return {[]|object}
 */
function createGradientColorsFromRoot(
  rootColor: string,
  paletteColors: ColorPalette,
  sequenceColorNames: string[],
): Record<string, string> {
  // Edge case for white, we shift the gradientColors one to the right
  const NUM_OF_COLORS = rootColor === '#FFFFFF' ? 6 : 5;
  let gradientColors = [];

  if (_.isString(rootColor)) {
    rootColor = hexToHsbExact(rootColor);
  }
  const rootBrightness = rootColor.brightness;
  const distanceToWhite = getDistanceToWhite(rootColor);
  const brightnessBucket = Math.floor(
    (5 * rootBrightness) / (rootBrightness + distanceToWhite),
  );

  for (let i = 0; i < NUM_OF_COLORS; i++) {
    let newBrightness;
    let newSaturation = rootColor.saturation;

    if (i < brightnessBucket) {
      newBrightness = ((i + 1) / (brightnessBucket + 1)) * rootBrightness;
    } else if (i === brightnessBucket) {
      newBrightness = rootBrightness;
    } else {
      newBrightness =
        ((i - brightnessBucket) / (5 - brightnessBucket)) *
          (100 - rootBrightness) +
        rootBrightness;
      newSaturation = rootColor.saturation / (i - brightnessBucket + 1);
    }

    gradientColors.unshift(
      hsbToHex({
        hue: rootColor.hue,
        saturation: newSaturation,
        brightness: newBrightness,
      }),
    );
  }

  if (!_.isEmpty(paletteColors) && !!sequenceColorNames) {
    const firstColor = hexToHsbExact(paletteColors[sequenceColorNames[0]]);
    const lastColor = hexToHsbExact(paletteColors[sequenceColorNames[4]]);

    if (getDistanceToWhite(firstColor) > getDistanceToWhite(lastColor)) {
      gradientColors.reverse();
    }
    gradientColors = _.zipObject(sequenceColorNames, gradientColors);
  }

  return gradientColors;
}

/**
 * @deprecated Use the same method from @/theme package
 */
function getColorInHex(color: Color): string {
  if (typeof color === 'string') {
    if (color.startsWith('rgba')) {
      return rgbToHex(rgbaStringToObject(color));
    }
    if (color.startsWith('rgb')) {
      return rgbToHex(rgbStringToObject(color));
    }
  }

  if (typeof color === 'object') {
    if (typeof color?.saturation === 'number') {
      return hsbToHex(color);
    }
    if (typeof color?.red === 'number') {
      return rgbToHex(color);
    }
    if (typeof color?.lightness === 'number') {
      return hslToHex(color);
    }
  }
  return color;
}

function getColorInRgb(color: Color): RGBColorObject {
  const hex = getColorInHex(color);

  return hexToRgb(hex);
}

/**
 * @deprecated Use the same method from @/theme package
 */
function isSameColors(baseColor: Color, targetColor: Color): boolean {
  const baseColorRgb = getColorInRgb(baseColor);
  const targetColorRgb = getColorInRgb(targetColor);

  return Object.keys(baseColorRgb).every(
    (colorKey) =>
      Math.abs(baseColorRgb[colorKey] - targetColorRgb[colorKey]) <= 1,
  );
}

/**
 * https://habr.com/ru/post/304210/
 * @deprecated Use the same method from @/theme package
 * @param hex
 * @returns number in range 0..1
 */
function getLumaPerceivedLightness(hex: string) {
  const { red, green, blue } = hexToRgb(hex);

  // prettier-ignore
  return (
    red    * 0.2126 +
    green  * 0.7152 +
    blue   * 0.0722
  ) / 255;
}

export {
  rgbToHsb,
  rgbToHsbExact,
  rgbToHex,
  hsbToRgb,
  hsbToHex,
  hexToRgb,
  hexToHsb,
  hexToHsbExact,
  hslToHsb,
  hsbToHsl,
  rgbStringToObject,
  rgbObjectToString,
  rgbaStringToObject,
  rgbaObjectToString,
  getDistanceToWhite,
  getLumaPerceivedLightness,
  createGradientColorsFromRoot,
  getColorInHex,
  isSameColors,
};
