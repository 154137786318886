import type {
  CompStructure,
  ContentRole,
  FieldContentRole,
} from '@wix/document-services-types';
import { ReportError } from './types';

const createCompIdToStructureMapInternal = (
  map: CompStructure[],
  compStructure: CompStructure,
): void => {
  map.push(compStructure);
  compStructure.components?.forEach((childStructure) => {
    createCompIdToStructureMapInternal(map, childStructure as CompStructure);
  });
};

export const createFlatStructureMap = (
  rootCompStructure: CompStructure,
): CompStructure[] => {
  const flatStructureMap = [] as CompStructure[];
  createCompIdToStructureMapInternal(flatStructureMap, rootCompStructure);
  return flatStructureMap;
};

export const getRandomNumber = (maxOffset = 99) => {
  return Math.floor(Math.random() * maxOffset);
};

export const findRepeaterStructure = (
  flatStructureMap: CompStructure[],
): CompStructure | undefined => {
  return flatStructureMap.find((compStructure) => {
    return compStructure.componentType === 'wysiwyg.viewer.components.Repeater'; // TODO
  });
};

export const getIsTranslationKey = (structure: CompStructure) => {
  return structure.source?.current === 'translation';
};

export const getIsRepeaterItem = (compStructure: CompStructure) => {
  return compStructure.data?.overrides || compStructure.design?.overrides;
};

export const getRepeaterItemsKeysIfExists = (
  flatCompStructureMap: CompStructure[],
): string[] | undefined => {
  const repeaterStructure = findRepeaterStructure(flatCompStructureMap);
  return repeaterStructure?.data.items;
};

export const isFieldContentRole = (
  contentRole: ContentRole,
): contentRole is FieldContentRole => {
  return (<FieldContentRole>contentRole)?.fieldRole !== undefined;
};

export const getCompData = (structure: CompStructure, itemKey?: string) => {
  return itemKey ? structure.data?.overrides[itemKey] : structure.data;
};

export const initReportDebugData = (
  presetStructure: CompStructure,
  isDebugMode?: boolean,
): ReportError => {
  return (msg: string, props: any = {}) => {
    if (!isDebugMode) {
      return;
    }
    window.console.log(`INJECT: ${msg}`, {
      ...props,
      presetStructure,
    });
  };
};
