import { getSvgNameForComp } from './utils/getSvgNameForComp';
import { getWBUIcon, toWBU, toWBUR } from './utils/svgForCompUtil';
import { imageTransform } from '@wix/santa-editor-utils';
import serviceTopology from '../serviceTopology/serviceTopology';
import { getStyleForSvg } from './utils/getStyleForSvg';
import { backgroundUtils } from '@/util';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef, Size } from 'types/documentServices';
import type { IconInfo } from 'types/core';
import type { AdditionalImageData } from '@/addPanelInfra';
import type { CSSProperties } from 'react';

const disabledIconColor = '#D5D5D5';

const compsWithRealImages = [
  'wysiwyg.viewer.components.ClipArt',
  'wysiwyg.viewer.components.WPhoto',
];

const DEFAULT_CONTAINER_SIZE = 50;

const originsForApplyingNewIcons = ['rcm', 'layers'];

const iconOverridesMap = {
  [toWBU('Box')]: (css: React.CSSProperties) => {
    css.margin = '4.5px';
    return 'container';
  },
};

export function getSrcByImageData(
  imageData: AdditionalImageData,
  containerSize?: Size,
) {
  containerSize = containerSize || {
    width: DEFAULT_CONTAINER_SIZE,
    height: DEFAULT_CONTAINER_SIZE,
  };
  const imageTransfromData = imageTransform.getData(
    imageTransform.fittingTypes.SCALE_TO_FILL,
    { width: imageData.width, height: imageData.height, id: imageData.uri },
    {
      width: containerSize.width || DEFAULT_CONTAINER_SIZE,
      height: containerSize.height || DEFAULT_CONTAINER_SIZE,
      alignment: imageTransform.alignTypes.CENTER,
      htmlTag: 'bg',
    },
  );
  return {
    url: `${serviceTopology.staticMediaUrl}/${imageTransfromData.uri}`,
    css: imageTransfromData.css.container,
  };
}
function getImageSrc(
  compRef: CompRef,
  containerSize: Size,
  editorAPI: EditorAPI,
) {
  const compData = editorAPI.components.data.get(compRef);
  if (
    !compData ||
    (compData.type !== 'ImageList' && compData.type !== 'Image')
  ) {
    return null;
  }
  const imageData =
    compData.type === 'ImageList' ? compData.items[0] : compData;

  return getSrcByImageData(imageData, containerSize);
}

const overrideIconAndCssIfApplicable = (
  svgName: string,
  css: React.CSSProperties,
) => {
  return iconOverridesMap[svgName]?.(css) || svgName;
};

export function getCompIconInfo(
  compRef: CompRef,
  compType: string,
  containerSize: Size,
  editorAPI: EditorAPI,
  origin: string,
): IconInfo {
  let src, svgName, css;
  const allowWBUIconsByOrigin = originsForApplyingNewIcons.includes(origin);

  if (compsWithRealImages.includes(compType)) {
    const imageData = getImageSrc(compRef, containerSize, editorAPI);

    if (imageData) {
      src = imageData.url;
      css = imageData.css;
    } else {
      svgName = getSvgNameForComp(
        editorAPI,
        compType,
        compRef,
        allowWBUIconsByOrigin,
      );
    }
  } else {
    svgName = getSvgNameForComp(
      editorAPI,
      compType,
      compRef,
      allowWBUIconsByOrigin,
    );
    css = getStyleForSvg(editorAPI, compType, compRef);
  }

  // due to the specifical style changes in different modes, some components should keep the local icon, but adjust to the layout of WBU icons
  if (allowWBUIconsByOrigin) {
    css = css || {};
    svgName = overrideIconAndCssIfApplicable(svgName, css);
  }
  return { src, svgName, css };
}

export const getBackgroundType = (editorAPI: EditorAPI, compRef: CompRef) => {
  const background =
    editorAPI.components.design.get(compRef)?.background ||
    editorAPI.pages.popupPages.getCurrentPopupData()?.pageBackgrounds?.desktop
      ?.ref;

  if (!background) {
    return null;
  }

  const backgroundSetToNone =
    !background.colorLayers &&
    !background.mediaRef &&
    background.colorOpacity === 0;

  if (backgroundSetToNone) {
    return 'none';
  }
  if (!background.mediaRef) {
    return 'Color';
  }
  return background.mediaRef.videoId ? 'WixVideo' : 'Image';
};

export const getCompBackgroundMedia = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  isDisabled = false,
) => {
  if (isDisabled) {
    return { backgroundColor: disabledIconColor, opacity: 1 };
  }
  const background =
    editorAPI.components.design.get(compRef)?.background ||
    editorAPI.pages.popupPages.getCurrentPopupData()?.pageBackgrounds?.desktop
      ?.ref;

  if (!background) {
    return { opacity: 0 };
  }

  const currentMedia: CSSProperties & {
    underlayOpacity?: number;
    underlayBackgroundImage?: string;
  } = backgroundUtils.getCurrentMedia(editorAPI, background, true);

  if (background.mediaRef) {
    const backgroundCss = backgroundUtils.getBackgroundCSS(
      editorAPI,
      background,
      true,
    );

    currentMedia.opacity = background.mediaRef.opacity ?? 1;
    currentMedia.underlayOpacity =
      background.colorLayers?.[0].opacity ?? background.colorOpacity;
    currentMedia.underlayBackgroundImage = backgroundCss.backgroundImage;
  } else {
    currentMedia.opacity =
      background.colorLayers?.[0].opacity ?? background.colorOpacity ?? 0;
  }

  return currentMedia;
};

export { getWBUIcon, toWBU, toWBUR };
