import constants from '@/constants';
import { toWBU, toWBUR } from './svgForCompUtil';
import { getRefComponentAppData } from '@/documentServices';

import type { IconNameResolver } from './types';

export const getRefComponentIconName: IconNameResolver = (
  editorAPI,
  compRef,
) => {
  const appData = getRefComponentAppData(editorAPI.dsRead, compRef);
  // NOTE:
  // - isWixTPA -- app is created by Wix (Wix TPA)
  // - !isWixTPA -- app is created by user
  return appData?.isWixTPA ? toWBU('AppMarket') : toWBUR('WidgetCanvas');
};

export const getAppWidgetIconName: IconNameResolver = (editorAPI, compRef) => {
  const childType = editorAPI.components.getType(
    editorAPI.components.getChildren(compRef),
  );

  if (childType === constants.COMP_TYPES.SEARCH_BOX) {
    return toWBU('Search');
  }

  return toWBU('AppMarket');
};
