import _ from 'lodash';
import { isNewStageGuidesEnabled, getHorizontalSnapData } from './snapToUtils';
import { getAllowedMargins } from './snapDataUtils';
import type { StageRect } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { CompData, CompLayout, CompRef } from 'types/documentServices';
import { SnapCandidateType, type SnapCandidate } from './snapTo.types';
import constants from '@/constants';

type Axis = 'x' | 'y';

const getEqualDistanceCandidates = function (
  currentPageId: string,
  stageRect: StageRect,
  compLayout: CompLayout,
  snapCandidates: SnapCandidate[],
  axis: Axis,
) {
  const visibleCandidates = snapCandidates.filter(
    _.partial(isLayoutVisible, stageRect),
  );
  const equalDistanceCandidates = filterEqualDistanceCandidates(
    visibleCandidates,
    currentPageId,
  );
  if (axis === 'x') {
    const horizontalCenterCandidates = filterCenterCandidates(
      compLayout,
      equalDistanceCandidates,
      'y',
      'height',
    );
    if (horizontalCenterCandidates.length > 0) {
      return addAxisAlignmentCoordinate(
        horizontalCenterCandidates,
        compLayout.y + compLayout.height / 2,
      );
    }
    return filterHorizontalCandidates(equalDistanceCandidates, compLayout);
  }
  const verticalCenterCandidates = filterCenterCandidates(
    compLayout,
    equalDistanceCandidates,
    'x',
    'width',
  );
  if (verticalCenterCandidates.length > 0) {
    return addAxisAlignmentCoordinate(
      verticalCenterCandidates,
      compLayout.x + compLayout.width / 2,
    );
  }
  return filterVerticalCandidates(equalDistanceCandidates, compLayout);
};

function filterEqualDistanceCandidates(
  candidates: SnapCandidate[],
  currentPageId: string,
) {
  candidates = candidates.filter((pointer) => pointer.comp !== undefined);
  return candidates.filter(
    (pointer) =>
      pointer.comp.id !== 'SITE_HEADER' &&
      pointer.comp.id !== 'SITE_FOOTER' &&
      pointer.comp.id !== currentPageId,
  );
}

function filterCenterCandidates(
  compLayout: CompLayout,
  candidatesPointersAndLayouts: SnapCandidate[],
  axis: Axis,
  dimension: 'height' | 'width',
) {
  return candidatesPointersAndLayouts.filter((candidatePointerAndLayout) => {
    const centerXValue = compLayout[axis] + compLayout[dimension] / 2;
    const candidateLayout = candidatePointerAndLayout.layout;
    return (
      centerXValue === candidateLayout[axis] + candidateLayout[dimension] / 2
    );
  });
}

function filterCandidatesByCompBoundry(
  candidatesPointersAndLayouts: SnapCandidate[],
  compBound: number,
  axis: Axis,
) {
  const dimension = axis === 'x' ? 'width' : 'height';
  const compBoundryCandidates = candidatesPointersAndLayouts.filter(
    (candidatePointerAndLayout) => {
      const candidateLayout = candidatePointerAndLayout.layout;
      return (
        compBound === candidateLayout[axis] ||
        compBound === candidateLayout[axis] + candidateLayout[dimension]
      );
    },
  );
  return compBoundryCandidates.length > 0
    ? addAxisAlignmentCoordinate(compBoundryCandidates, compBound)
    : compBoundryCandidates;
}

function filterHorizontalCandidates(
  candidatesPointersAndLayouts: SnapCandidate[],
  compLayout: CompLayout,
) {
  const compBottom = compLayout.y + compLayout.height;
  const compTop = compLayout.y;
  const topCandidates = filterCandidatesByCompBoundry(
    candidatesPointersAndLayouts,
    compTop,
    'y',
  );
  const bottomCandidates = filterCandidatesByCompBoundry(
    candidatesPointersAndLayouts,
    compBottom,
    'y',
  );

  return topCandidates.concat(bottomCandidates);
}

function filterVerticalCandidates(
  candidatesPointersAndLayouts: SnapCandidate[],
  compLayout: CompLayout,
) {
  const compRight = compLayout.x + compLayout.width;
  const compLeft = compLayout.x;
  const leftCandidates = filterCandidatesByCompBoundry(
    candidatesPointersAndLayouts,
    compLeft,
    'x',
  );
  const rightCandidates = filterCandidatesByCompBoundry(
    candidatesPointersAndLayouts,
    compRight,
    'x',
  );

  return leftCandidates.concat(rightCandidates);
}

function addAxisAlignmentCoordinate(
  candidates: SnapCandidate[],
  axisValue: number,
) {
  return candidates.map((candidate) => ({ ...candidate, axisValue }));
}

function isLayoutVisible(stageRect: StageRect, compData: CompData) {
  const { layout } = compData;
  return (
    stageRect.right >= layout.x &&
    layout.x + layout.width >= stageRect.left && // intersection x
    stageRect.bottom >= layout.y &&
    layout.y + layout.height >= stageRect.top // intersection y
  );
}

const createContainerWidthSnapCandidates = (
  editorAPI: EditorAPI,
): SnapCandidate[] => {
  const [comp] = editorAPI.selection.getSelectedComponents();

  if (!isNewStageGuidesEnabled()) return [];

  const mouseAction = editorAPI.mouseActions.getRegisteredMouseMoveAction();
  const directionName = mouseAction?.params?.directionName;
  const isResize = mouseAction.type === constants.MOUSE_ACTION_TYPES.RESIZE;

  if (!isResize || directionName === 'top' || directionName === 'bottom')
    return [];

  const { snappedToLeft, snappedToRigth } = getHorizontalSnapData(
    editorAPI,
    comp,
  );

  if (!snappedToLeft && !snappedToRigth) return [];

  const containerWidthOptions = snappedToLeft
    ? [0.25, 0.34, 0.5, 0.67, 0.75]
    : [0.25, 0.33, 0.5, 0.66, 0.75];

  const parent = editorAPI.components.getContainerOrScopeOwner(comp);
  const parentLayout = editorAPI.components.layout.getRelativeToScreen(parent);
  const boxBorders = 2;

  const candidates = containerWidthOptions.map((widthPercentage) => {
    return {
      type: SnapCandidateType.ContainerWidthPercentage,
      layout: {
        x: parentLayout.x + Math.round(parentLayout.width * widthPercentage),
        y: parentLayout.y + boxBorders,
        height: parentLayout.height - 2 * boxBorders,
        width: 0,
      },
      comp: parent,
      isDynamicLayout: false,
      style: {
        fill: '#116DFF',
        stroke: '#116DFF',
      },
    };
  });

  return candidates;
};

const createStageCenterSnapCandidates = (
  editorAPI: EditorAPI,
): SnapCandidate[] => {
  if (!isNewStageGuidesEnabled()) return [];

  return [
    {
      type: SnapCandidateType.StageCenter,
      layout: {
        y: 0,
        x: editorAPI.site.getWidth() / 2,
        height: editorAPI.site.getHeight(),
        width: 0,
      },
      isDynamicLayout: false,
    },
  ];
};

const createMarginIndicatorsSnapCandidates = (
  editorAPI: EditorAPI,
  validCompsToSnap: CompRef[],
) => {
  if (!isNewStageGuidesEnabled()) return [];

  const selectedComps = editorAPI.selection.getSelectedComponents();

  if (selectedComps.length !== 1 || validCompsToSnap.length === 0) return [];

  const nonSectionLikeOrPageComponents = validCompsToSnap.filter(
    (comp) =>
      !editorAPI.sections.isSectionLike(comp) &&
      !editorAPI.components.is.page(comp),
  );

  const componentWithContainerLayout = nonSectionLikeOrPageComponents.map(
    (component) => {
      const container =
        editorAPI.components.getContainerOrScopeOwner(component);
      const containerLayout =
        editorAPI.components.layout.getRelativeToScreen_rect(container);

      return {
        component,
        containerLayout,
      };
    },
  );

  const componentsWithAllowedMargins = componentWithContainerLayout
    .map(({ component, containerLayout }) => {
      const componentLayout =
        editorAPI.components.layout.getRelativeToScreen_rect(component);
      const margin = getAllowedMargins(componentLayout, containerLayout);

      return margin ? { margin, containerLayout } : null;
    })
    .filter(Boolean);

  if (componentsWithAllowedMargins.length === 0) return [];

  const candidates = componentsWithAllowedMargins.flatMap(
    ({ margin, containerLayout }) => {
      const candidates: SnapCandidate[] = [];

      if (margin.left) {
        candidates.push({
          type: SnapCandidateType.MarginIndicator,
          layout: {
            x: containerLayout.x + containerLayout.width - margin.left,
            y: containerLayout.y,
            height: containerLayout.height,
            width: 0,
          },
          isDynamicLayout: false,
        });
      }

      if (margin.right) {
        candidates.push({
          type: SnapCandidateType.MarginIndicator,
          layout: {
            x: containerLayout.x + margin.right,
            y: containerLayout.y,
            height: containerLayout.height,
            width: 0,
          },
          isDynamicLayout: false,
        });
      }

      if (margin.top) {
        candidates.push({
          type: SnapCandidateType.MarginIndicator,
          layout: {
            x: 0,
            y: containerLayout.y + containerLayout.height - margin.top,
            height: 0,
            width: containerLayout.width,
          },
          isDynamicLayout: false,
        });
      }

      if (margin.bottom) {
        candidates.push({
          type: SnapCandidateType.MarginIndicator,
          layout: {
            x: 0,
            y: containerLayout.y + margin.bottom,
            height: 0,
            width: containerLayout.width,
          },
          isDynamicLayout: false,
        });
      }

      const candaidatesWithRoundedCoords = candidates.map((candidate) => {
        const coordKeys = ['x', 'y'] as const;
        coordKeys.forEach((key) => {
          candidate.layout[key] = Math.round(candidate.layout[key]);
        });
        return candidate;
      });

      return candaidatesWithRoundedCoords;
    },
  );

  return candidates;
};

const isContainerWidthCandidate = (candidate: SnapCandidate) =>
  candidate?.type === SnapCandidateType.ContainerWidthPercentage;

const isStageCenterCandidate = (candidate: SnapCandidate) =>
  candidate?.type === SnapCandidateType.StageCenter;

const isMarginIndicatorCandidate = (candidate: SnapCandidate) =>
  candidate?.type === SnapCandidateType.MarginIndicator;

export {
  isContainerWidthCandidate,
  isStageCenterCandidate,
  isMarginIndicatorCandidate,
  isLayoutVisible,
  getEqualDistanceCandidates,
  createContainerWidthSnapCandidates,
  createStageCenterSnapCandidates,
  createMarginIndicatorsSnapCandidates,
};
