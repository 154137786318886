// @ts-nocheck
import _ from 'lodash';
import { translate } from '@/i18n';
import * as utils from '@wix/santa-editor-utils';

const AVAILABLE_LANGUAGES_VALUES = [
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'tr',
  'he',
];
const LANGUAGES_SUPPORTED_BY_TWITTER = [
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'tr',
];
const LANGUAGES_SUPPORTED_BY_FACEBOOK = [
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'kr',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'tr',
];
const LANGUAGES_SUPPORTED_BY_PAYPAL = [
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'tr',
];
const LANGUAGES_SUPPORTED_BY_ITUNES = [
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'jp',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'tr',
];
const LANGUAGES_SUPPORTED_BY_GOOGLE_MAPS = [
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'tr',
];
const LANGUAGE_TRANSLATION_KEY_PREFIX = 'Supported_Languages_';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/map
const LANGUAGES_LIST = _.map(AVAILABLE_LANGUAGES_VALUES, function (langValue) {
  return {
    value: langValue,
    label: translate(LANGUAGE_TRANSLATION_KEY_PREFIX + langValue.toUpperCase()),
  };
});

function getLanguageCode() {
  return utils.editorModel.languageCode;
}

function getLocalizedLink(link, optionalLangPlaceholder?) {
  const langCode = getLanguageCode();
  const placeHolder = optionalLangPlaceholder || '//www.';
  let langPrefix;
  if (langCode === 'en') {
    if (optionalLangPlaceholder) {
      langPrefix = 'www';
    } else {
      langPrefix = '//www.';
    }
  } else if (optionalLangPlaceholder) {
    langPrefix = langCode;
  } else {
    langPrefix = `//${langCode}.`;
  }

  link = link.replace(placeHolder, langPrefix);
  return link;
}

export default {
  getLanguagesList() {
    return LANGUAGES_LIST;
  },
  getTwitterLanguages() {
    return LANGUAGES_SUPPORTED_BY_TWITTER;
  },
  getFacebookLanguages() {
    return LANGUAGES_SUPPORTED_BY_FACEBOOK;
  },
  getITunesLanguages() {
    return LANGUAGES_SUPPORTED_BY_ITUNES;
  },
  getPaypalLanguages() {
    return LANGUAGES_SUPPORTED_BY_PAYPAL;
  },
  getGoogleMapsLanguages() {
    return LANGUAGES_SUPPORTED_BY_GOOGLE_MAPS;
  },
  getLanguageKeyByLang(lang) {
    return `LANGS_${lang}`;
  },
  getLanguageCode,
  getLocalizedLink,
};
