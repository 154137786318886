export const PANELS = {
  PANEL_SIZE_TO_KEEP_IN_VIEW: 145,
  PIN_TOO_BIG: {
    PANEL_NAME: 'panels.focusPanels.sizeRestrictionPanel',
    PIN_TOO_BIG_DONT_SHOW_AGAIN: 'pinTooBig.dontShowAgain',
  },
  FAKE_PROGRESS_BAR: 'panels.focusPanels.fakeProgressBarPanel',
  PANELS_TO_SHRINK_WHEN_SIDE_PANEL_OPEN: [
    'rEditor.panels.siteSettingsPanel',
    'platformPanels.dashboardPanel',
    'platformPanels.modalPanel',
    'tpa.compModals.tpaSettingsModal',
    'panels.focusPanels.organizeImagesPanel',
  ],
  SAVED_COMPONENTS: {
    RENAME_COMPONENT_PANEL_NAME:
      'panels.focusPanels.renameSavedComponentsPanel',
    DELETE_COMPONENT_PANEL_NAME:
      'panels.focusPanels.deleteSavedComponentsPanel',
  },
  SAVE_PUBLISH: {
    FAIL_PANEL: 'savePublish.panels.common.failPanel',
    CONNECT_DOMAIN: 'savePublish.panels.connectDomain',
  },
  PROMOTIONAL: {
    QR_CODE_MAIN_PANEL: 'panels.promotionalPanels.qrCodePublishedSitePanel',
    ADVANCED_MENUS_MIGRATION_PANEL:
      'panels.promotionalPanels.advancedMenusMigrationPanel',
  },
  ANCHOR: {
    ANCHORS_MENU_MANAGE_PANEL:
      'compPanels.panels.VerticalAnchorsMenu.managePanel',
    ANCHOR_SETINGS_PANEL: 'compPanels.panels.Anchor.settingsPanel',
  },
  SIDE_PANEL_DIVIDER_WIDTH: 1,
  PLAYER_PANEL: 'panels.focusPanels.howToVideoPlayer',
  SECTIONS: {
    SETTINGS_PANEL: 'compPanels.panels.ClassicSection.settingsPanel',
  },
  NATIVE_PANELS: {
    settings: 'settingsPanel',
    layout: 'layoutPanel',
    design: 'designPanel',
    manage: 'managePanel',
    tableManage: 'columnManagerPanel',
  },
  FOURTH_PUBLISH_SUCCESS_PANEL: {
    PANEL_NAME: 'savePublish.panels.publish.fourthPublishSuccessPanel',
  },
  SITE_ACCESS: {
    PANEL_NAME: 'panels.focusPanels.siteAccessPanel',
  },
};
