import _ from 'lodash';
import { getInnerTextString } from './textUtils';
import { HTML_TAG_CLOSING, MediaType } from './consts';
import {
  createFlatStructureMap,
  getCompData,
  getIsRepeaterItem,
  getIsTranslationKey,
  getRepeaterItemsKeysIfExists,
  initReportDebugData,
  isFieldContentRole,
} from './utils';
import {
  getImgObj,
  getVideoObj,
  isBackground,
  isMediaPlayer,
  isSupportedVideoPlayer,
  isTransparentVideo,
  isVideoBackground,
  pickContentItem,
  selectMedia,
  updatePosters,
  updateVideoProps,
  updateVideoQualities,
} from './mediaUtils';
import { PresetContentFieldRole } from './consts';

import type {
  ImageMediaItem,
  MediaItem,
  VideoMediaItem,
} from '@wix/adi-content-api';
import type { CompStructure } from '@wix/document-services-types';
import type { ProviderContent, UserData } from '@wix/editor-content-provider';
import { getAlignTypeFromFaces } from './focalPointUtils';
import { getIsSocialLinksBar, injectSocialLinks } from './socialLinksUtils';
import { ReportError } from './types';

const injectText = (
  compStructureData: CompStructure['data'],
  contentItem: ProviderContent | null,
  fieldRole: PresetContentFieldRole,
  userData: UserData,
) => {
  let newText = contentItem?.[fieldRole] ?? userData[fieldRole];
  const text = compStructureData?.text;
  if (!newText || !text) {
    return;
  }
  if (fieldRole === PresetContentFieldRole.Address) {
    newText = userData[fieldRole].googleFormattedAddress;
  }
  const innerText = getInnerTextString(text);
  const capturingInnerText = `${HTML_TAG_CLOSING}${innerText}`;
  const capturingNewText = `${HTML_TAG_CLOSING}${newText}`;
  compStructureData.text = text.replace(capturingInnerText, capturingNewText);
};

const injectCropData = (
  imageData: CompStructure['data'],
  newImage: ImageMediaItem,
) => {
  if (!imageData.crop) {
    return;
  }

  const cropRatio = imageData.crop.width / imageData.crop.height;
  const isCropPortrait = cropRatio < 1;
  const isCropLandscape = cropRatio > 1;
  const isCropSquare = cropRatio === 1;

  const isNewImagePortrait = newImage.height > newImage.width;
  const isNewImageLandscape = newImage.height < newImage.width;
  const isNewImageSquare = newImage.height === newImage.width;

  if (isCropPortrait) {
    const newWidth = Math.floor(cropRatio * newImage.height);
    imageData.crop.width =
      newWidth > newImage.width ? newImage.width : newWidth;
    imageData.crop.height = newImage.height;
    imageData.crop.x = Math.floor(
      Math.abs(newImage.width - imageData.crop.width) / 2,
    );
    imageData.crop.y = 0;
  }

  if (isCropLandscape) {
    const newHeight = Math.floor(newImage.width / cropRatio);
    imageData.crop.height =
      newHeight > newImage.height ? newImage.height : newHeight;
    imageData.crop.width = newImage.width;
    imageData.crop.y = Math.floor(
      Math.abs(newImage.height - imageData.crop.height) / 2,
    );
    imageData.crop.x = 0;
  }

  if (isCropSquare && isNewImagePortrait) {
    imageData.crop.height = imageData.crop.width = newImage.width;
    imageData.crop.x = 0;
    imageData.crop.y = Math.floor(
      Math.abs(newImage.height - imageData.crop.height) / 2,
    );
  }

  if (isCropSquare && isNewImageLandscape) {
    imageData.crop.width = imageData.crop.height = newImage.height;
    imageData.crop.y = 0;
    imageData.crop.x = Math.floor(
      Math.abs(newImage.width - imageData.crop.width) / 2,
    );
  }

  if (isCropSquare && isNewImageSquare) {
    imageData.crop.height = newImage.height;
    imageData.crop.width = newImage.width;
    imageData.crop.y = 0;
    imageData.crop.x = 0;
  }
};

const injectImageData = (
  imageData: CompStructure['data'],
  newImage: ImageMediaItem,
) => {
  imageData.uri = newImage.uri || newImage.relativeUri;
  imageData.width = newImage.width;
  imageData.height = newImage.height;
  imageData.alt = newImage.Title ?? '';
  imageData.title = '';
};

const injectImageObject = (
  imageData: CompStructure['data'],
  newImage: ImageMediaItem,
) => {
  injectCropData(imageData, newImage);
  injectImageData(imageData, newImage);
};

const injectImageBackgroundAlignType = (
  designData: CompStructure['design'],
  image: ImageMediaItem,
) => {
  const alignType = getAlignTypeFromFaces(image);
  const isRepeaterImage = designData?.original?.background?.mediaRef;
  if (designData?.background?.mediaRef) {
    designData.background.alignType = alignType;
  } else if (isRepeaterImage) {
    designData.original.background.alignType = alignType;
  }
};

const injectImage = (
  structure: CompStructure,
  fieldRole: PresetContentFieldRole,
  userData: UserData,
  usedMediaGlobal: MediaItem[],
  reportDebug: ReportError,
  media?: MediaItem[],
  extraMedia?: MediaItem[],
  itemKey?: string,
) => {
  const imageObj = getImgObj(structure, itemKey);
  if (!imageObj) {
    return;
  }

  let newImage: any;

  // currently this is relevant only for logo
  const imageFromUserData = userData[fieldRole];
  if (imageFromUserData) {
    newImage = imageFromUserData;
  } else if (media) {
    newImage = selectMedia(
      MediaType.Image,
      fieldRole,
      usedMediaGlobal,
      media,
      extraMedia,
    );
  } else {
    return;
  }

  if (!newImage) {
    return;
  }

  if (isBackground(structure)) {
    injectImageBackgroundAlignType(structure.design, newImage);
  }
  reportDebug('injectImage', {
    name: newImage.Title,
    fieldRole,
    newImage,
    media,
    extraMedia,
  });
  injectImageObject(imageObj, newImage);
};

const injectVideo = (
  structure: CompStructure,
  fieldRole: PresetContentFieldRole,
  usedMediaGlobal: MediaItem[],
  reportDebug: ReportError,
  media?: MediaItem[],
  extraMedia?: MediaItem[],
  itemKey?: string,
) => {
  const isNotSupported =
    !isMediaPlayer(structure) &&
    !isSupportedVideoPlayer(structure, itemKey) &&
    !isVideoBackground(structure);
  if (!media || isTransparentVideo(structure) || isNotSupported) {
    return;
  }
  const videoObj = getVideoObj(structure, itemKey);
  if (!videoObj) {
    return;
  }
  const newVideo = selectMedia(
    MediaType.Video,
    fieldRole,
    usedMediaGlobal,
    media,
    extraMedia,
  ) as VideoMediaItem;

  if (!newVideo) {
    return;
  }
  reportDebug('injectVideo', {
    fieldRole,
    newVideo,
    media,
    extraMedia,
  });
  updateVideoProps(videoObj, newVideo);
  updateVideoQualities(videoObj, newVideo);
  updatePosters(videoObj, newVideo);
};

const injectMapsUserData = (compData: any, userData: UserData) => {
  if (!compData?.locations?.[0]?.address || !userData.address.coordinates) {
    return;
  }
  compData.locations[0].address = userData.address.googleFormattedAddress;
  compData.locations[0].latitude = userData.address.coordinates.latitude;
  compData.locations[0].longitude = userData.address.coordinates.longitude;

  // These are deprecated props, we update them for backward compatibility
  if (compData.address && compData.latitude && compData.longitude) {
    compData.address = userData.address.googleFormattedAddress;
    compData.latitude = userData.address.coordinates.latitude;
    compData.longitude = userData.address.coordinates.longitude;
  }
};

const injectContent = (
  structure: CompStructure,
  content: ProviderContent[],
  userData: UserData,
  usedMediaGlobal: MediaItem[],
  reportDebug: ReportError,
  itemKey?: string,
  repeaterItemIndex?: number,
) => {
  const { contentRole } = structure;
  if (!contentRole || !isFieldContentRole(contentRole)) {
    return;
  }

  const fieldRole = contentRole.fieldRole as PresetContentFieldRole;
  const contentItem = pickContentItem(structure, content, repeaterItemIndex);
  const compData = getCompData(structure, itemKey);
  const { media, extraMedia } = contentItem ?? {};

  injectText(compData, contentItem, fieldRole, userData);
  injectMapsUserData(compData, userData);
  injectImage(
    structure,
    fieldRole,
    userData,
    usedMediaGlobal,
    reportDebug,
    media,
    extraMedia,
    itemKey,
  );
  injectVideo(
    structure,
    fieldRole,
    usedMediaGlobal,
    reportDebug,
    media,
    extraMedia,
    itemKey,
  );
};

const injectContentToStructure = (
  structure: CompStructure,
  itemsKeys: string[] | undefined,
  content: ProviderContent[],
  userData: UserData,
  usedMediaGlobal: MediaItem[],
  reportDebug: ReportError,
) => {
  const isTranslationKey = getIsTranslationKey(structure);
  if (isTranslationKey) {
    return;
  }
  const isSocialLinksBar = getIsSocialLinksBar(structure);
  if (isSocialLinksBar) {
    injectSocialLinks(structure, userData);
  }
  const isRepeaterItem = getIsRepeaterItem(structure);
  if (isRepeaterItem) {
    itemsKeys?.forEach((itemKey: string, index: number) => {
      injectContent(
        structure,
        content,
        userData,
        usedMediaGlobal,
        reportDebug,
        itemKey,
        index,
      );
    });
  } else {
    injectContent(structure, content, userData, usedMediaGlobal, reportDebug);
  }
};

export const injectPresetStructure = (
  presetStructure: CompStructure,
  content: ProviderContent[],
  userData: UserData,
  usedMediaGlobal: MediaItem[],
  isDebugMode?: boolean,
): CompStructure => {
  const newStructure = _.cloneDeep(presetStructure);
  const flatCompStructureMap = createFlatStructureMap(newStructure);
  const itemsKeys = getRepeaterItemsKeysIfExists(flatCompStructureMap);

  const reportDebug = initReportDebugData(presetStructure, isDebugMode);

  flatCompStructureMap.forEach((singleCompStructure) => {
    injectContentToStructure(
      singleCompStructure,
      itemsKeys,
      content,
      userData,
      usedMediaGlobal,
      reportDebug,
    );
  });
  return newStructure;
};
