// @ts-nocheck
import * as util from '@/util';
import constants from '@/constants';
import type { DSRead } from 'types/documentServices';

export interface TranslatedItem {
  translation: string;
  originalIndex: number;
  status: 'SUCCESS' | 'ITEM_TOO_LONG';
}

interface TranslationResponse {
  status: {
    totalFailure: number;
    totalSuccess: number;
  };
  translatedItems: TranslatedItem[];
}

export interface Translatable {
  text: string;
  text_format: string;
}
type TranslatableText = Translatable | Array<Translatable>;

const translate = <T extends TranslatableText>(
  dsRead: DSRead,
  sourceLanguage: string,
  targetLanguage: string,
  targetLanguageCountryCode?: string,
  text: T,
  reportWords?: boolean,
): Promise<T extends string ? TranslatedItem : TranslatedItem[]> => {
  const { instance } = dsRead.tpa.app.getDataByAppDefId(
    constants.MULTILINGUAL.linguistDefId,
  );

  return util.http
    .fetch('/_api/machine-translation/v2/translations/translate', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        sourceLanguage,
        targetLanguage,
        targetFlag: targetLanguageCountryCode,
        translator: 'GOOGLE',
        translatables: [text].flat(),
        reportWords,
      }),
      headers: new Headers({ Authorization: instance }),
    })
    .then((response) => response.json())
    .then((response: TranslationResponse) =>
      Array.isArray(text)
        ? response.translatedItems.sort(
            ({ originalIndex: a }, { originalIndex: b }) => a - b,
          )
        : response.translatedItems[0],
    );
};

interface TranslateAllSIteParams {
  originalLanguageId: string;
  currentLanguageId: string;
  currentLanguageFlag: string;
  instance: string;
}

const autoTranslateSite = ({
  originalLanguageId,
  currentLanguageId,
  currentLanguageFlag,
  instance,
}: TranslateAllSIteParams): Promise<void> => {
  return util.http.fetch('/_api/site-translator/v2/translate', {
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify({
      mainLanguage: { language: originalLanguageId },
      translated_language: {
        language: currentLanguageId,
        flag: currentLanguageFlag,
      },
    }),
    headers: new Headers({ Authorization: instance }),
  }) as unknown as Promise<void>;
};

export { translate, autoTranslateSite };
