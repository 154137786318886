import { getDsOrigin } from './dsOrigin';
import * as localModeUtils from '../localMode/localModeUtils';
import { isSectionsEnabled } from '../sections';
import { WorkspaceModesApiKey } from '@/apis';
import { fixedStage } from '@/util';

import type { BILoggerDSParams } from 'types/bi';

import type { EditorAPI } from '@/editorAPI';

type ValuesOfReturnFnValues<T extends object> = {
  [K in keyof T]: T[K] | (() => T[K]);
};

export const getBILoggerDSParams = (editorAPI: EditorAPI) => {
  const params: ValuesOfReturnFnValues<BILoggerDSParams> = {
    site_id: editorAPI.dsRead.generalInfo.getSiteId(),
    viewerName: editorAPI.dsRead.env.viewer.getViewerName(),
    viewerVersion: editorAPI.dsRead.env.viewer.getViewerVersion(),
    isDraft: () => editorAPI.dsRead?.generalInfo.isDraft() || false,
    isMobileOnlyComponentSelected: () => {
      const selectedComponents = editorAPI.selection.getSelectedComponents();

      if (
        selectedComponents.length === 1 &&
        selectedComponents[0]?.id &&
        // `mobile.mobileOnlyComponents?.` because editor X, which uses classic editor panels API, does not have `dsRead.mobile.mobileOnlyComponents`
        // https://github.com/wix-private/editorx-santa-editor-bridge/blob/090fc4089df8655b642fd44bda4ae2b490c201d2/packages/santa-editor-bridge/src/main/classicEditorPackage.tsx#L718-L722
        // TODO: cleanup when editorX ready with real `dsRead.mobile` mock
        // https://github.com/wix-private/santa-editor/pull/37946
        typeof editorAPI.mobile.mobileOnlyComponents?.isMobileOnlyComponent ===
          'function' &&
        editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(
          selectedComponents[0].id,
        )
      ) {
        return true;
      }
    },
    focused_page_id: () => editorAPI.dsRead?.pages.getFocusedPageId(),
    viewmode: () => editorAPI.viewMode.get(),
    editor_working_mode: () =>
      editorAPI.isMobileEditor() ? 'mobile' : 'desktop',
    isSection: () => isSectionsEnabled(),
    isZoomOut: () => editorAPI.zoomMode.isInZoomMode(),
    multilingual: () => {
      if (editorAPI.dsRead?.language.isCurrentLanguageSecondary?.()) {
        // NOTE: next code is copied from the `stateManagement.multilingual.utils.getCurrentAndOriginal(editorAPI);` to fix the circular deps issue
        const currentLanguageCode = editorAPI.language.current.get();
        const originalLanguageCode =
          editorAPI.language.original.get()?.code || currentLanguageCode;

        return `${originalLanguageCode},${currentLanguageCode}`;
      }
    },
    ds_origin: getDsOrigin(),
    workspace_mode: editorAPI.host.getAPI(WorkspaceModesApiKey).getMode(),
    stage_type: () => {
      const siteWidth = editorAPI.site.getWidth();
      return fixedStage.isFixedStageEnabled() && siteWidth === 1200
        ? 'fixed_stage'
        : `full_width_${siteWidth}`;
    },
  };

  if (localModeUtils.isLocalModeEnabled()) {
    const localModeFields = localModeUtils.getLocalModeFieldsExtraBiFields();

    Object.entries(localModeFields).forEach(([key, value]) => {
      params[key as keyof typeof params] = value;
    });
  }

  return params;
};
