// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';

const { BEHAVIOR_NAME } = constants.INLINE_POPUP;

const getToggleTarget = (editorAPI, comp) =>
  _.chain(comp)
    .thru(editorAPI.components.behaviors.get)
    .find(['behavior.name', BEHAVIOR_NAME])
    .get('behavior.targetId')
    .thru(editorAPI.components.get.byId)
    .value();

const isOpen = (editorAPI, comp) => editorAPI.components.is.visible(comp);

const isTargetOpen = (editorAPI, comp) => {
  const target = getToggleTarget(editorAPI, comp);
  if (!target) {
    return false;
  }
  isOpen(editorAPI, target);
};

export { isOpen, isTargetOpen, getToggleTarget };
