export {
  getComponentDefinedScopes,
  getComponentDefinedScopesAndScopesList,
  isComponentScoped,
  hasComponentScopeChildren,
  getComponentScopeChildren,
  getComponentScopeOwner,
  getComponentScopeOwnerAncestors,
  findComponentScopeOwner,
  getRefComponentRootChildType,
  getRefComponentStageData,
  getRefComponentAppData,
  getRefComponentAppDefinitionName,
  getRefComponentDisplayName,
  getRefComponentCodeNickname,
  getRefComponentRootChild,
} from './private/componentScopeSelectors';
