import * as inlinePopupUtils from '../inlinePopup/inlinePopupUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export function canSelectCompConsideringSpotlightStage(
  editorAPI: EditorAPI,
  focusedContainer: CompRef,
  comp: CompRef,
) {
  return (
    !editorAPI.components.is.spotlightStageContainer(focusedContainer) ||
    editorAPI.components.isDescendantOfComp(comp, focusedContainer) ||
    editorAPI.utils.isSameRef(comp, focusedContainer) ||
    editorAPI.utils.isSameRef(
      inlinePopupUtils.getToggleTarget(editorAPI, comp),
      focusedContainer,
    )
  );
}
