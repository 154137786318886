// @ts-nocheck
import * as util from '@/util';

function fetchJson(url, onSuccess, onError) {
  util.http.fetchJson(url).then(onSuccess).catch(onError);
}

function getVideoUrl(videoId) {
  return `https://vimeo.com/${videoId}`;
}

function getVideoIdFromUrl(url) {
  // TODO: add support for additional vimeo url formats
  const vimeoUrlRegex = /vimeo\.com\/(\d+)$/gi;
  const match = vimeoUrlRegex.exec(url);
  if (match?.length && match[1]) {
    //if there is a match, the second group is the id we want
    return match[1];
  }
}

function verifyVideoUrl(url, onSuccess, onError) {
  const videoId = getVideoIdFromUrl(url);
  if (!videoId) {
    onError();
  }
  const dataUrl = `https://vimeo.com/api/v2/video/${videoId}.json`;
  fetchJson(dataUrl, onSuccess, onError);
}

export { verifyVideoUrl, getVideoUrl, getVideoIdFromUrl };
