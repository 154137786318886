import * as coreBi from '@/coreBi';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const SLIDE_SHOW_TYPES = [
  'wysiwyg.viewer.components.BoxSlideShow',
  'wysiwyg.viewer.components.StateBox',
  'wysiwyg.viewer.components.StateStrip',
  'wysiwyg.viewer.components.StripContainerSlideShow',
];

const getCurrentSlideIndex = (dsRead: AnyFixMe, boxSlideShow: AnyFixMe) => {
  const slideIndex = dsRead.components.behaviors.getRuntimeState(boxSlideShow);
  return slideIndex.currentIndex;
};

const getAllSlides = (dsRead: AnyFixMe, compRef: AnyFixMe) =>
  dsRead.layouters.getNonMasterChildren(compRef);

const findCurrentSlide = (dsRead: AnyFixMe, compRef: AnyFixMe) => {
  const allSlides = getAllSlides(dsRead, compRef);
  return allSlides[getCurrentSlideIndex(dsRead, compRef)];
};

const getComponentsInCurrentSlide = (dsRead: AnyFixMe, compRef: AnyFixMe) => {
  return dsRead.deprecatedOldBadPerformanceApis.components.getChildren(
    findCurrentSlide(dsRead, compRef),
  );
};

const isBoxSlideShowType = (dsRead: AnyFixMe, compRef: AnyFixMe) => {
  return SLIDE_SHOW_TYPES.includes(dsRead.components.getType(compRef));
};

const openApplyToAllPanel = (
  editorAPI: EditorAPI,
  callingPanelBoundingRect: any,
  slideShowCompRef: CompRef,
  origin: string,
) => {
  const panelProps = {
    top: callingPanelBoundingRect.top + 72,
    left: callingPanelBoundingRect.left - 12,
    width: 290,
    origin,
    slideShowCompRef,
  };
  if (editorAPI.zoomMode.isInZoomMode()) {
    const pageClientRect =
      editorAPI.components.layout.measure.getBoundingClientRect(
        editorAPI.pages.getFocusedPage(),
      );
    const editorScroll = editorAPI.ui.scroll.getScroll();
    panelProps.top = window.innerHeight / 4 + editorScroll.scrollTop;
    panelProps.left = pageClientRect.absoluteLeft;
  }
  editorAPI.panelManager.openPanel(
    'rEditor.panels.slideShowBgApplyPanel',
    panelProps,
    true,
  );
  editorAPI.bi.event(
    coreBi.events.boxSlideShow.APPLY_ON_OTHER_SLIDES_CLICKED,
    editorAPI.components.getDefaultBiParams(
      editorAPI.selection.getSelectedComponents(),
    ),
  );
};

export {
  SLIDE_SHOW_TYPES,
  getCurrentSlideIndex,
  getAllSlides,
  findCurrentSlide,
  getComponentsInCurrentSlide,
  isBoxSlideShowType,
  openApplyToAllPanel,
};
