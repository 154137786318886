import {
  MediaType,
  PresetContentFieldRole,
  TRANSPARENT_VIDEO_MEDIA_FEATURE,
} from './consts';
import { getCompData, getRandomNumber } from './utils';

import type {
  CompStructure,
  FieldContentRole,
} from '@wix/document-services-types';
import type {
  ImageMediaItem,
  MediaItem,
  VideoFileOutputVideo,
  VideoMediaItem,
} from '@wix/adi-content-api';
import type { ProviderContent } from '@wix/editor-content-provider';
import type { Quality } from './types';

const isMediaAlreadyUsed = (
  mediaItem: MediaItem,
  usedMediaGlobal: MediaItem[],
) => {
  return usedMediaGlobal.some((usedMediaItem) => {
    return usedMediaItem.relativeUri === mediaItem.relativeUri;
  });
};

const getAllUnusedMedia = (
  media: MediaItem[],
  usedMediaGlobal: MediaItem[],
): MediaItem[] => {
  return media.filter((mediaItem) => {
    return !isMediaAlreadyUsed(mediaItem, usedMediaGlobal);
  });
};

export const clearUsedMediaByMediaType = (
  mediaType: MediaType,
  usedMediaGlobal: MediaItem[],
) => {
  let i = 0;
  while (i < usedMediaGlobal.length) {
    const usedMediaItem = usedMediaGlobal[i];
    const notSameMediaType = usedMediaItem.mediaType !== mediaType;
    if (notSameMediaType) {
      ++i;
      continue;
    }
    usedMediaGlobal.splice(i, 1);
  }
};

const clearUsedMediaByFieldRoleAndType = (
  fieldRole: string,
  mediaType: MediaType,
  usedMediaGlobal: MediaItem[],
) => {
  let i = 0;
  while (i < usedMediaGlobal.length) {
    const usedMediaItem = usedMediaGlobal[i];
    const mediaNotSupported =
      !isImageMediaItem(usedMediaItem) && !isVideoMediaItem(usedMediaItem);
    const notSameMediaType = usedMediaItem.mediaType !== mediaType;
    if (mediaNotSupported || notSameMediaType) {
      ++i;
      continue;
    }
    const notSameTag = !usedMediaItem.systemTags?.includes(fieldRole); // This is split for ts check, not all have systemTags
    if (notSameTag) {
      ++i;
      continue;
    }
    usedMediaGlobal.splice(i, 1);
  }
};

const pickMediaItemRandomly = (filteredMedia: MediaItem[]): MediaItem => {
  const randomOffset = getRandomNumber(filteredMedia.length - 1);
  const [newMedia] = filteredMedia.splice(randomOffset, 1) as MediaItem[];
  return newMedia;
};

const isImageMediaItem = (
  mediaItem: MediaItem,
): mediaItem is ImageMediaItem => {
  return (mediaItem as ImageMediaItem).mediaType === MediaType.Image;
};

const isVideoMediaItem = (
  mediaItem: MediaItem,
): mediaItem is VideoMediaItem => {
  return (mediaItem as VideoMediaItem).mediaType === MediaType.Video;
};

const filterMediaByRole = (
  fieldRole: PresetContentFieldRole,
  media: MediaItem[],
): MediaItem[] => {
  return media.filter((mediaItem) => {
    if (isImageMediaItem(mediaItem) || isVideoMediaItem(mediaItem)) {
      return mediaItem.systemTags?.includes(fieldRole);
    }
    return false;
  });
};

const filterMediaByType = (
  mediaType: MediaType,
  media?: MediaItem[],
): MediaItem[] => {
  if (!media) {
    return [];
  }
  return media.filter((mediaItem) => {
    return mediaItem.mediaType === mediaType;
  });
};

const filterMedia = (
  mediaType: MediaType,
  fieldRole: PresetContentFieldRole,
  usedMediaGlobal: MediaItem[],
  media: MediaItem[],
  extraMedia?: MediaItem[],
) => {
  const allMediaByType = filterMediaByType(mediaType, media);
  const filteredUsedMedia = getAllUnusedMedia(allMediaByType, usedMediaGlobal);
  let filteredMedia = filterMediaByRole(fieldRole, filteredUsedMedia);

  const isAllFilteredByFieldRole =
    !filteredMedia.length && !!filteredUsedMedia.length;
  const isOnlyOneMediaItem =
    allMediaByType.length === 1 && !filteredMedia.length;
  if (isAllFilteredByFieldRole || isOnlyOneMediaItem) {
    if (extraMedia?.length) {
      const extraMediaByType = filterMediaByType(mediaType, extraMedia);
      const filteredUsedExtraMedia = getAllUnusedMedia(
        extraMediaByType,
        usedMediaGlobal,
      );
      filteredMedia = filterMediaByRole(fieldRole, filteredUsedExtraMedia);
      if (filteredMedia.length) {
        return filteredMedia;
      }
    }

    const usedMediaByType = filterMediaByType(mediaType, usedMediaGlobal);
    const usedMediaByFieldRole = filterMediaByRole(fieldRole, usedMediaByType);
    if (usedMediaByFieldRole.length) {
      clearUsedMediaByFieldRoleAndType(fieldRole, mediaType, usedMediaGlobal);
      return filterMedia(
        mediaType,
        fieldRole,
        usedMediaGlobal,
        media,
        extraMedia,
      );
    }

    filteredMedia = filteredUsedMedia;
  }

  const hasUsedMedia = !!usedMediaGlobal.length;
  const usedAllMedia = !filteredMedia.length;
  if (usedAllMedia && hasUsedMedia) {
    clearUsedMediaByMediaType(mediaType, usedMediaGlobal);
    return filterMedia(
      mediaType,
      fieldRole,
      usedMediaGlobal,
      media,
      extraMedia,
    );
  }

  return filteredMedia;
};

export const selectMedia = (
  mediaType: MediaType,
  fieldRole: PresetContentFieldRole,
  usedMediaGlobal: MediaItem[],
  media: MediaItem[],
  extraMedia?: MediaItem[],
): MediaItem | null => {
  const filteredMedia = filterMedia(
    mediaType,
    fieldRole,
    usedMediaGlobal,
    media,
    extraMedia,
  );
  if (!filteredMedia.length) {
    return null;
  }

  const newMedia = pickMediaItemRandomly(filteredMedia);
  usedMediaGlobal.push(newMedia);
  return newMedia;
};

export const isImage = (compStructure: CompStructure) => {
  // TODO get this type from EE
  return compStructure.componentType === 'wysiwyg.viewer.components.WPhoto';
};

export const isBackground = (compStructure: CompStructure) => {
  return (
    compStructure.design?.background?.mediaRef?.type === 'Image' ||
    compStructure.design?.original?.background?.mediaRef?.type === 'Image'
  );
};

const getAllNotListContent = (
  content: ProviderContent[],
): ProviderContent[] => {
  return content.filter((contentItem) => contentItem.listItemId === undefined);
};

const getListItemId = (
  compStructure: CompStructure,
  repeaterItemIndex?: number,
): number | undefined => {
  return repeaterItemIndex !== undefined
    ? repeaterItemIndex
    : (compStructure.contentRole as FieldContentRole)?.listItemId;
};

export const pickContentItem = (
  compStructure: CompStructure,
  content: ProviderContent[],
  repeaterItemIndex?: number,
): ProviderContent | null => {
  const listItemId = getListItemId(compStructure, repeaterItemIndex);

  if (listItemId === undefined) {
    const items = getAllNotListContent(content);
    if (!items.length) {
      return null;
    }
    const randomOffset = getRandomNumber(items.length - 1);
    return items[randomOffset];
  }

  return content.find((contentItem) => {
    return contentItem.listItemId === listItemId;
  }) as ProviderContent;
};

export const getImgObj = (
  singleCompStructure: CompStructure,
  itemKey?: string,
) => {
  if (isImage(singleCompStructure)) {
    return getCompData(singleCompStructure, itemKey);
  }

  if (isBackground(singleCompStructure)) {
    return itemKey
      ? singleCompStructure.design.overrides[itemKey].background.mediaRef
      : singleCompStructure.design.background.mediaRef;
  }
};

export const isSupportedVideoPlayer = (
  { data, componentType }: CompStructure,
  itemKey?: string,
) => {
  // TODO compType from EE
  const isFile = itemKey
    ? data?.overrides[itemKey].videoType === 'file'
    : data?.videoType === 'file';
  const isVideoPlayer = componentType === 'wixui.VideoPlayer';
  return isVideoPlayer && isFile;
};

export const isMediaPlayer = ({ componentType }: CompStructure) => {
  // TODO compType from EE
  return componentType === 'wysiwyg.viewer.components.MediaPlayer';
};

export const getVideoObj = (
  singleCompStructure: CompStructure,
  itemKey?: string,
) => {
  if (isSupportedVideoPlayer(singleCompStructure, itemKey)) {
    return itemKey
      ? singleCompStructure.data.overrides[itemKey].videoRef
      : singleCompStructure.data.videoRef;
  }

  if (
    isMediaPlayer(singleCompStructure) ||
    isVideoBackground(singleCompStructure)
  ) {
    return singleCompStructure.design.background.mediaRef;
  }
};

export const isVideoBackground = (singleCompStructure: CompStructure) => {
  return singleCompStructure.design?.background?.mediaRef?.type === 'WixVideo';
};

const pickVideoQualityByFormat = (
  newVideo: VideoMediaItem,
  quality: Quality,
): VideoFileOutputVideo | undefined => {
  const DEFAULT_FORMAT = 'mp4';
  return newVideo.fileOutput.video.find((video) => {
    const formatToFind = quality.url?.split('/')[3] ?? DEFAULT_FORMAT;
    return video.quality === quality.quality && video.format === formatToFind;
  });
};

export const updateVideoQualities = (
  videoObj: any,
  newVideo: VideoMediaItem,
) => {
  videoObj.qualities.forEach((quality: Quality, index: number) => {
    const newItem = pickVideoQualityByFormat(newVideo, quality);
    if (newItem) {
      quality.url = newItem.url;
      quality.width = newItem.width;
      quality.height = newItem.height;
    } else {
      videoObj.qualities.splice(index, 1);
    }
  });
};

export const updatePosters = (videoObj: any, newVideo: VideoMediaItem) => {
  if (!videoObj.generatedPosters) {
    return;
  }
  videoObj.generatedPosters = newVideo.fileOutput.image.map((image) => {
    return image.url.split('media/')[1];
  });
};

export const updateVideoProps = (videoObj: any, newVideo: VideoMediaItem) => {
  videoObj.duration = newVideo.fileInput.duration / 1000;
  videoObj.videoId = newVideo.relativeUri;
  videoObj.title = newVideo.Title;
  videoObj.posterImageRef.description = newVideo.fileBaseUrl;
  videoObj.posterImageRef.uri =
    newVideo.fileOutput.image[0].url.split('media/')[1];
  if (!videoObj.adaptiveVideo) {
    return;
  }
  videoObj.adaptiveVideo = [];
};

export const isTransparentVideo = (compStructure: CompStructure): boolean => {
  return (
    isMediaPlayer(compStructure) &&
    !!compStructure.design?.background?.mediaRef?.mediaFeatures?.includes(
      TRANSPARENT_VIDEO_MEDIA_FEATURE,
    )
  );
};
