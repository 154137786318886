import constants from '@/constants';
import { sections } from '@/util';
import * as appStudioUtils from '../appStudio/appStudioUtils';
import * as localModeUtils from '../localMode/localModeUtils';

const isNewTopBarEnabled = (): boolean =>
  !appStudioUtils.isAppStudio() && !localModeUtils.isLocalModeEnabled();

const getHeightConst = (): number =>
  isNewTopBarEnabled()
    ? constants.UI.TOPBAR_HEIGHT
    : constants.UI.OLD_TOPBAR_HEIGHT;

const getUpperDeckHeightConst = (): number => Math.floor(getHeightConst() / 2);

// 37 - based on g-zoom-mode-preview-top scss variable
const getZoomModeHeightConst = (): number => {
  if (sections.isSectionsEnabled()) {
    return getHeightConst();
  }

  return isNewTopBarEnabled() ? getUpperDeckHeightConst() : 37;
};

const getOffsetTopWhenToolsHidden = (): number =>
  isNewTopBarEnabled()
    ? constants.UI.OFFSET_TOP_WHEN_TOOLS_HIDDEN
    : constants.UI.OLD_OFFSET_TOP_WHEN_TOOLS_HIDDEN;

export {
  isNewTopBarEnabled,
  getHeightConst,
  getUpperDeckHeightConst,
  getZoomModeHeightConst,
  getOffsetTopWhenToolsHidden,
};
