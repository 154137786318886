import { animation } from '@/util';

interface ScrollPosition {
  x: number;
  y: number;
}

const waitForAnimationScrollEnd = async (
  getScroll: () => ScrollPosition,
): Promise<void> => {
  await animation.waitForAnimationEnd<ScrollPosition>(getScroll, 3);
};

export { waitForAnimationScrollEnd };
