export enum WORKSPACE_RIGHT_PANEL_NAMES {
  SWITCH_LAYOUT = 'switch_layout_panel',
  QUICK_EDIT = 'quick_edit',
  SCAN_SWITCH_LAYOUT_PRESET = 'scan-switch-layout-preset',
  AI_PAGE_WRITER = 'ai-page-writer',
}

export enum WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES {
  QUICK_EDIT_DRILL_IN = 'quick_edit_drill_in',
  QUICK_EDIT_COMP_PANEL = 'quick_edit_component_panel',
}

export enum WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES {
  EDIT_SECTION = 'edit_section',
  EDIT_SECTION_MULTILINGUAL = 'edit_section_panel_multilingual',
}

export const WORKSPACE_RIGHT_PANEL_TAB_GROUP_TITLES: Record<
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  string
> = {
  [WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES.EDIT_SECTION]:
    'edit_section_panel_header',
  [WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES.EDIT_SECTION_MULTILINGUAL]:
    'edit_section_panel_multilingual_header',
};

export const WORKSPACE_RIGHT_PANEL_GROUPS_TAB_ORDER: Record<
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  WORKSPACE_RIGHT_PANEL_NAMES[]
> = {
  [WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES.EDIT_SECTION]: [
    WORKSPACE_RIGHT_PANEL_NAMES.QUICK_EDIT,
    WORKSPACE_RIGHT_PANEL_NAMES.SWITCH_LAYOUT,
    WORKSPACE_RIGHT_PANEL_NAMES.SCAN_SWITCH_LAYOUT_PRESET,
  ],
  [WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES.EDIT_SECTION_MULTILINGUAL]: [
    WORKSPACE_RIGHT_PANEL_NAMES.QUICK_EDIT,
    WORKSPACE_RIGHT_PANEL_NAMES.SWITCH_LAYOUT,
  ],
};
