// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';

const YT_VIDEOS_URL = 'https://www.googleapis.com/youtube/v3/videos';
const API_KEY = 'AIzaSyC-CGohESUF9bCBy1jFRQYFOR-iwbsCYPg';

function getVideoUrl(videoId) {
  return `http://www.youtube.com/watch?v=${videoId}`;
}

function getVideoIdFromUrl(url) {
  // TODO: add support for additional youtube url formats
  // Test for long youtube url: http://youtube.com/watch?[...&]v=[VIDEO_ID]
  const YTLongUrl = /(?:youtube\.com\/watch[^\s]*[\?&]v=)([\w-]+)/g;
  // Test for short youtube url: http://youtu.be/[VIDEO_ID]
  const YTShortUrl = /(?:youtu\.be\/)([\w-]+)/g;

  const match = YTLongUrl.exec(url) || YTShortUrl.exec(url);
  if (match?.length && match[1]) {
    //if there is a match, the second group is the id we want
    return match[1];
  }
}

function verifyVideoUrl(url, onSuccess, onError) {
  const videoId = getVideoIdFromUrl(url);
  if (!videoId) {
    onError();
    return;
  }
  const fetchUrl = `${YT_VIDEOS_URL}?key=${API_KEY}&part=snippet&id=${videoId}`;

  util.http
    .fetchJson(fetchUrl)
    .then((data) => {
      if (!_.isEmpty(data.items)) {
        onSuccess(url);
        return;
      }
      return Promise.reject();
    })
    .catch(onError);
}

export { verifyVideoUrl, getVideoUrl, getVideoIdFromUrl };
